.Footer {
	@include hyphenate;

	padding: rem(72) 0 rem(48);

	@include mobile {
		padding: rem(48) 0 rem(48);
	}
}

.Footer--row-main {
	@include grid-container;

	@include mobile {
		display: flex;
		flex-direction: column;
		gap: rem(36);
	}
}

.Footer--section {
	grid-column: span 2;
}

.Footer--section-logo {
	grid-column: span 4;

	@include mobile {
		order: 100;
	}
}

.Footer--logo {
	max-width: rem(250);
	height: auto;

	@include mobile {
		margin-top: rem(12);
	}
}

.Footer--section-title {
	font-family: $title-font-family;
	font-size: rem(20);
	line-height: 1.2;
	color: $c-gray-new-text;
	margin-bottom: rem(16);
	margin-top: rem(32);

	@include mobile {
		margin-bottom: rem(12);
		font-size: rem(20);
	}

	&:first-child {
		margin-top: 0;
	}
}

.Footer--section-title-small {
	font-family: $base-font-family;
	font-size: rem(20);
	@include mobile {
		font-size: rem(20);
	}
}

.Footer--link-list {
	display: flex;
	flex-direction: column;
	gap: rem(4);
	list-style: none;
	margin: 0;
	padding: 0;

	@include mobile {
		gap: rem(8);
	}

	li {
		display: inline-flex;
	}

	a {
		max-width: 100%;
		font-size: rem(15.5);
		color: #777;

		&:hover {
			color: $c-gray-new-text;
		}
	}
}

.Footer--contact-html {
	p {
		font-size: rem(15.5);
		color: #777;
	}

	a {
		color: #777;

		&:hover {
			color: $c-gray-new-text;
		}
	}
}

.Footer--social-media,
.Footer--meta {
	margin-top: rem(62);

	@include mobile {
		margin-top: rem(40);
	}
}

.Footer--meta-links {
	display: flex;
	flex-wrap: wrap;
	gap: rem(8) rem(16);
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		display: inline-flex;
	}

	a {
		max-width: 100%;
		font-size: rem(13);
		color: $c-gray-new-text-light;

		&:hover {
			color: $c-gray-new-text;
		}

		@include tablet {
			font-size: rem(15.5);
		}
	}
}
