/* EntryPageHeader Component Styles */
.EntryPageHeader {
	margin-bottom: rem(80);

	@include mobile {
		margin-bottom: rem(56);
	}
}

.EntryPageHeader--breadcrumbs {
	margin: rem(32) 0 rem(106);

	@include mobile {
		margin-bottom: rem(60);
	}

	.EntryPageHeader--only-breadcrumbs & {
		margin-bottom: 0;
	}
}

.EntryPageHeader--text-content {
	max-width: $max-text-width;
}

.EntryPageHeader--title,
.EntryPageHeader--lead {
	font-family: $title-font-family;
}

.EntryPageHeader--title {
	@include coloredText;

	font-size: rem(80);
	line-height: 1.2;

	@include mobile {
		font-size: rem(56);
	}
}

.EntryPageHeader--lead {
	margin-top: rem(20);
	font-size: rem(24);
	line-height: 1.4;

	@include mobile {
		margin-top: rem(18);
		font-size: rem(22);
	}
}
