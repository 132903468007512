/* LargeText Component Styles */
.LargeText {
	@include component-gap-smaller;

	font-size: rem(30);

	@include mobile {
		font-size: rem(22);
	}
}

.LargeText--content {
	@include grid-container;
}

.LargeText--inner {
	grid-column: 3 / span 8;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

.LargeText--title {
	@include heading-xs;
}

// Narrow variant for contact details etc.
.LargeText.narrow {
	@include component-gap(l);

	.LargeText--inner {
		grid-column: 5 / span 4;

		@include mobile {
			grid-column: 1 / span 12;
		}
	}
}
