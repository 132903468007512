/* Pagination Component Styles */
.Pagination {
	@include font-size(14, 14);
	font-weight: bold;

	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}

.Pagination--link {
	display: block;
	height: rem(52);
	line-height: rem(50);
	padding: 0 rem(15);
	opacity: 0.2;
	text-align: center;

	@include no-touch() {
		transition: opacity $transition-duration-fast ease-in-out;

		&:hover {
			opacity: 1;
		}
	}

	&.current {
		opacity: 1;
		position: relative;
		width: rem(52);
		margin: 0 rem(15);
		padding: 0;

		&:before {
			content: '';
			@include full-size-absolute;
			display: block;
			border: 1px solid $c-gray-light;
			border-radius: rem(100);
		}
	}

	@include mobile-xs {
		padding: 0 mobile-rem(8);

		&.current {
			margin: 0 mobile-rem(8);
		}
	}
}

.Pagination--prev {
	margin-right: rem(15);

	@include mobile-xs {
		margin-right: mobile-rem(8);
	}
}

.Pagination--next {
	margin-left: rem(15);

	@include mobile-xs {
		margin-left: mobile-rem(8);
	}
}
