/* FilterCard Component Styles */
.FilterCard {
	display: block;
	overflow: hidden;
	background-color: $c-gray-light-new;
	border: 1px solid $c-gray-light-darker-new;
	border-radius: rem(16);

	@include mobile {
		border-radius: mobile-rem(12);
	}
}

.FilterCard--main,
.FilterCard--panel-content {
	padding: rem(28);
}

.FilterCard--main {
	position: relative;
	display: flex;
	gap: rem(28);
}

.FilterCard--main-text {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.FilterCard--main-logo {
	flex: 0 0 auto;
	width: rem(120);
	max-width: 25%;
	order: -1;
}

.FilterCard--panel {
	background-color: $c-white;
	transition: height $transition-duration-default ease-in-out;
}

.FilterCard--title,
.FilterCard--meta {
	color: $c-gray-new-text;
}

.FilterCard--title {
	font-family: $title-font-family;
	font-size: rem(28);
	line-height: 1.2;
}

.FilterCard--meta {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: rem(8) rem(16);
	margin-bottom: rem(16);
	font-size: rem(14);
	order: -1;

	@include mobile {
		justify-content: flex-start;
		margin-bottom: rem(32);
	}
}

.FilterCard--mark-and-date {
	display: flex;
	flex-wrap: wrap;
	gap: rem(8) rem(16);
}

.FilterCard--mark,
.FilterCard--author-title {
	font-weight: 700;
}

.FilterCard--mark {
	text-transform: uppercase;
}

.FilterCard--topic-categories {
	margin-top: rem(16);
}

.FilterCard--trigger {
	position: absolute;
	inset: 0;
	z-index: 1;

	width: 100%; // Required for Firefox.
	height: 100%; // Required for Firefox.

	&:hover + .FilterCard--trigger-text {
		color: $c-red-new-hover-dark;
	}

	&[aria-expanded='true'] + .FilterCard--trigger-text::after {
		mask-image: url(#{icon-encoded(minus)});
	}
}

.FilterCard--trigger-text {
	position: relative;
	display: flex;
	align-items: center;
	align-self: flex-start;
	flex: 0 0 auto;
	margin-top: rem(16);
	text-align: left;
	color: $c-red-new;

	&::after {
		@include bg-icon-style(plus);

		content: '';
		margin-left: rem(8);
		width: rem(16);
		height: rem(16);
	}
}

.FilterCard--description {
	font-size: rem(18);
	color: $c-gray-new-text-light;
}

.FilterCard--description-main {
	margin-top: rem(16);
}

.FilterCard--read-more-link {
	flex: 0 0 auto;
	margin-top: rem(16);
}
