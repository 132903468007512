/* AudioContent Component Styles */

.AudioContent {
	@include component-gap-smaller;

	@include mobile {
		@include font-size(17, 28, true);
	}
}

.AudioContent--content {
	@include grid-container;
}

.AudioContent--inner {
	position: relative;
	grid-column: 4 / span 5;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: rem($grid-gap);

	@include mobile {
		grid-column: 1 / span 12;
		grid-template-columns: repeat(4, 1fr);
		gap: rem($grid-gap-mobile);
	}
}

.AudioContent--image {
	position: relative;
	grid-column: 1 / span 2;
	grid-row: 1;

	overflow: hidden;
	height: 0;
	padding-bottom: 100%;
	border-radius: rem(10);

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $c-black;
		opacity: 0.4;
		z-index: 1;
	}

	@include mobile {
		grid-column: 1 / span 1;
	}
}

.AudioContent--iframe {
	display: none;
	width: 100%;

	iframe {
		display: block;
		width: 100%;
		height: auto;
	}
}

.AudioContent--button {
	position: absolute;
	inset: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.AudioContent--title {
	grid-column: 3 / span 3;

	@include mobile {
		grid-column: 2 / span 3;
	}
}

.AudioContent.playing {
	.AudioContent--iframe {
		display: block;
		grid-column: 1 / span 5;

		@include mobile {
			grid-column: 1 / span 4;
		}
	}

	.AudioContent--title,
	.AudioContent--image {
		display: none;
	}
}
