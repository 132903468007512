/* GradientBackdrop Component Styles */

.GradientBackdrop {
	@include full-size-absolute;
	z-index: 0;

	canvas {
		display: block;
		@include full-size-absolute;
	}
}
