/* TextBox Component Styles */
.TextBox {
	@include component-gap-smaller;
}

.TextBox--content {
	@include grid-container;
}

.TextBox--inner {
	grid-column: 4 / span 6;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

.TextBox--box {
	background: $c-gray-lightest;
	padding: rem(50);

	@include mobile {
		padding: mobile-rem(45) mobile-rem(25);
	}
}
