/* ArticleCard Component Styles */

.ArticleCard {
	position: relative;
	height: rem(560);
	color: $c-cold-gray;

	@include CardHoverAnim('.ArticleCard--title', 10);

	@include mobile {
		height: mobile-rem(227);
	}
}

.ArticleCard--main {
	@include Card--main;
}

.ArticleCard--title {
	@include heading-l;
	@include line-clamp(5);
	@include hyphenate;

	@include mobile {
		@include font-size(20, 26, true);
		@include line-clamp(4);
	}
}

.ArticleCard--header {
	@include Card--header;
}

.ArticleCard--type {
	@include Card--type;
}

.ArticleCard--date {
	@include Card--subtype;
}

.ArticleCard--badge {
	@include Card--badge;
	margin-left: rem(10);

	&:first-child {
		margin-left: auto;
	}
}

.ArticleCard--footer {
	@include Card--footer;
}

.ArticleCard--actions {
	margin-left: auto;
}

.ArticleCard--bg {
	z-index: -1;
}

// -------------------------------------------------
// Modifiers

.ArticleCard.bag {
	.ArticleCard--main {
		background: $c-red-light;
	}

	.ArticleCard--badge {
		color: $c-red-light;
	}
}

.ArticleCard.monthly {
	border-radius: rem(10);

	&:hover {
		box-shadow: $box-shadow-soft;
	}

	.ArticleCard--main {
		overflow: hidden;
		color: $c-gray-new-text;
		background-color: $c-blue-light;
	}

	.ArticleCard--badge {
		background: $c-gray-new-text;
	}
}
