/* MediaCard Component Styles */

.MediaCard--link {
	display: block;
}

.MediaCard--preview {
	position: relative;
	border-radius: rem(10);
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $c-black;
		opacity: 0;
		z-index: 1;
		transition: opacity $transition-duration-default ease-in-out;
	}

	@include no-touch {
		.MediaCard--link:hover & {
			&:before {
				opacity: 0.5;
			}
		}
	}
}

.MediaCard--button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	opacity: 0;
	transition: opacity $transition-duration-default ease-in-out;

	@include no-touch {
		.MediaCard--link:hover & {
			opacity: 1;
		}
	}
}

.MediaCard--caption {
	margin-top: rem(25);

	@include mobile {
		@include font-size(11, 14, true);
		margin-top: mobile-rem(6);
	}
}

.MediaCard--icon {
	display: inline-block;
	vertical-align: middle;
	margin-right: rem(5);
	margin-left: rem(-5);
	height: $base-line-height * 1rem;

	@include mobile {
		height: auto;
		margin-right: 0.2ex;

		.SvgIcon {
			width: mobile-rem(18);
			height: mobile-rem(18);
		}
	}
}
