/* TagList Component Styles */
.TagList {
	@include list-reset;
	display: flex;
	flex-flow: row wrap;
}

.TagList--tag-item {
	white-space: nowrap;
	margin-right: rem(8);

	&:last-child {
		margin-right: 0;
	}

	.TagList:not(.show-all) &.hidden {
		display: none;
	}
}

.TagList.compact {
	.TagList--tag-item {
		margin-right: rem(4);
	}
}
