@use 'sass:math';

/* CardSlider Component Styles */
.CardSlider {
	@include component-gap(xxl);

	.Slider--container:not(.swiper-container-initialized) {
		.Slider--slide {
			margin-right: rem($grid-gap);
		}
	}

	.Slider.slides-3 {
		.Slider--slide {
			width: calc(#{math.div(100%, 3)} - #{math.div(2, 3) * rem($grid-gap)});
		}
	}

	.Slider.slides-2 {
		.Slider--slide {
			width: calc(#{math.div(100%, 2)} - #{math.div(1, 2) * rem($grid-gap)});
		}
	}

	@include tablet-portrait {
		&:not(.empty) {
			.wrapper {
				padding-right: 0;
			}

			.ModuleTitle,
			.Slider--controls {
				padding-right: rem($side-padding);
			}

			.Slider--slide {
				&:last-child {
					padding-right: rem($side-padding);
				}
			}

			.Slider.slides-3 {
				.Slider--slide {
					// important needed to stop swiper from glitching with px units
					width: 45% !important;
				}
			}

			.Slider.slides-2 {
				.Slider--slide {
					width: 68% !important;
				}
			}
		}
	}

	@include mobile {
		&:not(.empty) {
			.Slider.slides-2,
			.Slider.slides-3 {
				.Slider--slide {
					width: calc(
						#{math.div(100%, 2)} - #{math.div(1, 2) * rem($grid-gap-mobile)}
					) !important;

					&:last-child {
						padding-right: rem($side-padding-mobile);
					}
				}
			}

			&.collection .Slider.slides-2 {
				.Slider--slide {
					width: 100% !important;
				}
			}
		}

		.Slider--container:not(.swiper-container-initialized) {
			.Slider--slide {
				margin-right: rem($grid-gap-mobile);
			}
		}
	}

	@include mobile-xs {
		&:not(.empty) {
			.Slider.slides-2,
			.Slider.slides-3 {
				.Slider--slide {
					width: 100% !important;

					&:last-child {
						padding-right: rem($side-padding-mobile);
					}
				}
			}
		}
	}
}

.CardSlider--content {
	@include grid-container;
}
