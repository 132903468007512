/* Breadcrumbs Component Styles */
.Breadcrumbs--list {
	display: flex;
	flex-wrap: wrap;
	font-size: rem(16);
	color: $c-gray-new-text-light;
	list-style: none;
	margin: 0;
	padding: 0;
}

.Breadcrumbs--separator {
	margin: 0 rem(8);
}

.Breadcrumbs--link {
	color: $c-gray-new-text-light;

	&:hover {
		color: $c-gray-new-text;
	}
}
.Breadcrumbs--text {
	color: $c-gray-new-text-disabled;
}
