/* MissionStatement Component Styles */
.MissionStatement {
	@include component-gap-standard;
}

.MissionStatement--content {
	display: flex;
	justify-content: center;
}

.MissionStatement--statement {
	display: flex;
	flex-direction: column;
	gap: rem(20);
	width: 100%;
	max-width: $max-text-width;
	line-height: 1.2;

	@include mobile {
		gap: rem(12);
	}
}

.MissionStatement--label {
	font-size: rem(16);
	font-weight: bold;

	@include mobile {
		font-size: rem(12);
	}
}

.MissionStatement--text {
	@include coloredText;
	@include hyphenate;

	font-family: $title-font-family;
	font-size: rem(56);

	@include mobile {
		font-size: rem(28);
	}
}
