/* Quote Component Styles */

.Quote {
	@include component-gap-smaller;
}

.Quote--content {
	@include grid-container;
}

.Quote--inner {
	grid-column: 5 / span 4;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

.Quote--quote {
	@include font-size(28, 38);
	font-weight: normal;

	&:before {
		content: '«';
	}

	&:after {
		content: '»';
	}
}
