/* StartpageHeader Component Styles */
.StartpageHeader {
	padding-top: rem(90);
	padding-bottom: rem(90);
	background-color: $c-cold-gray;
	background-image: url('/assets/img/bg-visual-intro.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: white;

	@include mobile {
		padding-top: rem(48);
		padding-bottom: rem(48);
		background-image: url('/assets/img/bg-visual-intro-mobile.png');
	}
}

.StartpageHeader--global-lead {
	font-family: $title-font-family;
	font-size: rem(24);
	max-width: $max-text-width;

	@include mobile {
		font-size: rem(22);
	}
}
