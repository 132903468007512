/* ContentSocialMediaButtons Component Styles */
.ContentSocialMediaButtons {
	@include component-gap(m);
}

.ContentSocialMediaButtons--content {
	@include grid-container;
}

.ContentSocialMediaButtons--inner {
	grid-column: 5 / span 5;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

.ContentSocialMediaButtons--title {
	@include heading-xs;
	margin-bottom: rem(31);
}
