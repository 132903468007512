/* Logo Component Styles */
.Logo {
	display: block;
}

.Logo--image {
	display: block;
	$height: 44;
	height: rem($height);
	width: rem(calc(276 / 55) * $height);
	image-rendering: -webkit-optimize-contrast;

	/*@include mobile {
		width: rvw(124px, $site-width-mobile);
		max-width: mobile-rem(190);
		height: auto;
	}*/

	@include mobile {
		width: auto;
		max-width: auto;
		height: mobile-rem(25);
	}
}
