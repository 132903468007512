@mixin title-font {
	font-family: $title-font-family;
	letter-spacing: -0.02em;
}

@mixin heading-l {
	@include title-font;
	@include font-size-2;
	font-weight: bold;
}

@mixin heading-xs {
	@include font-size(13, 15);
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: rem(17);
	letter-spacing: 0.03em;

	@include mobile {
		@include font-size(11, 13, true);
	}
}
