/* Default animation effect. Must have one or multiple child-elements with class `animate-target` */
.js [data-animate] {
	.animate-target {
		opacity: 0;
		transform: translateY(#{rem(30)});
		transition: transform 0.6s ease-out, opacity 0.4s ease-out;
		transition-delay: var(--staggerDelay, 0s);
	}

	&.animate-reveal {
		.animate-target {
			transform: translateY(0);
			opacity: 1;
		}
	}
}

.animate-debug {
	position: absolute;
	border: 1px solid red;
	top: 0;
	left: 0;
	z-index: 99999;
	pointer-events: none;
}
