@use 'sass:color';

/* CategoryLink Component Styles */
.EntryLink {
	@include transitionEffect('background-color');

	display: inline-flex;
	align-items: center;
	overflow: hidden;
	font-size: rem(14);
	line-height: 1.2;
	border-radius: rem(6);
	min-height: rem(36);
	padding: rem(3) rem(16) rem(6);
	background-color: $c-red-light;

	&:hover {
		background-color: color.adjust($c-red-light, $lightness: -5%);
	}
}
