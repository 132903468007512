/* AuthorInfo Component Styles */
.AuthorInfo {
	@include component-gap(l);
	@include font-size(14, 22);
}

.AuthorInfo--content {
	@include grid-container;
}

.AuthorInfo--inner,
.AuthorInfo--footnote {
	grid-column: 3 / span 8;

	@include mobile {
		grid-column: 1 / span 12;
		margin-left: 0;
	}
}

.AuthorInfo--inner {
	padding: rem(40) rem(220) rem(40) 0;
	border: 0 solid $c-gray-lightest;
	border-width: 1px 0;
	position: relative;

	@include mobile {
		padding: rem(40) 0;
	}
}

.AuthorInfo--footnote {
	margin-top: rem(48);
	color: rgba($c-black, 0.6);
	display: block;
	font-size: inherit;

	@include mobile {
		margin-top: mobile-rem(20);
	}
}

.AuthorInfo--info {
	@include font-size(11);
	color: rgba($c-black, 0.5);
	margin-bottom: rem(3);
}

.AuthorInfo--title {
	@include title-font;
	@include font-size(28, 36);
	font-weight: bold;
	margin-bottom: rem(12);

	@include mobile {
		margin-bottom: 0;
	}
}

.AuthorInfo--copy {
	margin-bottom: rem(12);
}

.AuthorInfo--link {
	@include icon(angle-right, after);
	padding: rem(5) 0;
	color: $c-red-new;

	&:hover {
		color: $c-red-new-hover-dark;
	}

	&:after {
		@include font-size(11);
	}
}

.AuthorInfo--image {
	position: absolute;
	top: rem(78);
	right: rem(61);
	width: rem(98);
	height: rem(98);

	@include mobile {
		position: static;
		top: auto;
		right: auto;
		width: mobile-rem(57);
		height: mobile-rem(57);
		order: -1;
		margin-right: mobile-rem(14);
	}
}

.AuthorInfo--author {
	@include mobile {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: mobile-rem(20);
	}
}
