/* Infograph Component Styles */
.Infograph {
	@include component-gap-standard;
}

.Infograph__figure {
	margin-top: rem(40);

	@include mobile {
		margin-top: rem(36);
	}
}

.Infograph__image {
	display: flex;
	height: auto;
	width: 100%;
	text-align: center;

	.Image--img {
		position: unset;
		display: inline-block;
		max-width: 100%;
		height: auto;
	}
}

.Infograph__figure-portrait {
	max-width: rem(720);
}

.Infograph__image-desktop {
	@include mobile {
		display: none;
	}
}

.Infograph__image-mobile {
	display: none;

	@include mobile {
		display: flex;
	}
}

.Infograph--caption {
	margin-top: rem(24);
	font-size: rem(18);

	@include mobile {
		margin-top: rem(18);
		font-size: rem(16);
	}
}

.Infograph__sources {
	margin-top: rem(24);

	@include mobile {
		margin-top: rem(18);
		font-size: rem(16);
	}

	.Infograph__has-caption & {
		margin-top: rem(6);
	}
}
