.Button {
	font-weight: bold;
	@include font-size(14);
	line-height: calc(16 / 14);
	cursor: pointer;
}

.Button:not(.minimal) {
	min-width: rem(170);
	min-height: rem(52);
	height: rem(52);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;
	padding: 0 rem(20);
	transition: color $transition-duration-default ease-in-out;
	white-space: nowrap;

	.Button--bg {
		@include full-size-absolute(true);
		border: 1px solid $c-gray-light;
		border-radius: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		transition: border-color $transition-duration-default ease-in-out;
	}

	&:active {
		.Button--bg {
			transition-duration: 0s;
			border-color: $c-red;
		}
	}

	@include no-touch {
		&:hover {
			.Button--bg {
				border-color: $c-red;
			}
		}
	}

	@include mobile {
		min-width: 0;
		padding: 0 mobile-rem(26);
	}
}

.Button--text {
	position: relative;
	z-index: 2;
	color: inherit;
	pointer-events: none;
	margin-bottom: rem(2);
}

.Button--avatar {
	@include full-size-absolute;
	pointer-events: none;

	.Image.circle {
		overflow: visible;
		border-radius: 0;
	}
}

// Variants
.Button.primary {
	.Button--bg {
		transform: translate3d(0, 0, 0);
		border: 0;
	}

	.Button--animated-bg {
		opacity: 0;
		transform: translate3d(0, 0, 0);
		transition: opacity $transition-duration-default ease-in-out;
	}

	@include no-touch {
		&:hover {
			.Button--animated-bg {
				opacity: 1;
			}
		}
	}
}

.Button.circle {
	width: rem(52);
	min-width: rem(52);
	padding: 0;
}

.Button.with-icon {
	.Button--text {
		display: none;
	}

	.SvgIcon {
		position: relative;
		display: block;
		z-index: 1;
	}
}

.Button.with-icon.text-outside {
	padding-bottom: rem(43);
	justify-content: flex-start;
	box-sizing: content-box;

	.Button--bg {
		height: rem(52);
		width: rem(52);
	}

	.Button--text {
		@include font-size(16);
		font-weight: normal;
		display: block;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	@include mobile {
		width: auto;
		padding-bottom: 0;

		.Button--text {
			position: static;
			left: auto;
			bottom: auto;
			transform: none;
			padding-left: rem(66);
		}
	}
}

.Button.minimal.with-icon {
	width: rem(26);
	height: rem(26);
	padding: rem(2);
	display: inline-block;
	box-sizing: border-box;

	.Button--bg {
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
	}

	.SvgIcon {
		max-width: 100%;
		max-height: 100%;
	}

	@include mobile {
		width: mobile-rem(16);
		height: mobile-rem(16);
		padding: mobile-rem(8);
		box-sizing: content-box;
	}
}

.Button.primary,
.Button.white {
	color: $c-white;

	.Button--animated-bg {
		opacity: 1;
	}
}

.Button:disabled,
.Button.disabled {
	color: $c-gray;
	cursor: not-allowed;

	&:not(.minimal) {
		.Button--bg {
			border-color: $c-gray-light;
		}
	}

	.Button--animated-bg {
		display: none;
	}
}

.Button.white {
	&:not(.minimal) {
		.Button--bg {
			border-color: $c-white;
		}
	}
}

@include mobile {
	// Render button as link on mobile
	.Button.mobile-link {
		min-width: 0;
		min-height: 0;
		padding: 0;
		height: auto;
		display: block;
		@include icon(angle-right, after);
		@include font-size(13, $is-mobile: true);
		white-space: nowrap;
		color: $c-red-new;

		&:hover {
			color: $c-red-new-hover-dark;
		}

		&:after {
			@include font-size(10, $is-mobile: true);
			display: inline;
			position: static;
			color: currentColor;
			margin-left: 0.2ex;
		}

		.Button--text {
			display: inline;
			color: inherit;
			position: static;
			z-index: unset;
			margin: 0;
		}

		.Button--bg {
			display: none;
		}

		.SvgIcon {
			display: none;
		}
	}
}
