/* ContentContextActions Component Styles */

.ContentContextActions {
	@include component-gap(l);
}

.ContentContextActions--content {
	@include grid-container;
}

.ContentContextActions--inner {
	grid-column: 4 / span 9;

	@include mobile {
		grid-column: 1 / span 12;
	}
}
