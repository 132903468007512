/* VideoContent Component Styles */

.VideoContent {
	@include component-gap-smaller;
}

.VideoContent--content {
	@include grid-container;
}

.VideoContent--inner {
	grid-column: 4 / span 5;

	@include mobile {
		grid-column: 1 / span 12;
	}
}
