.CookieBanner {
	padding: rem(24) 0;
	background-color: $c-white;
	box-shadow: 0px -2px 6px -2px rgba(0, 0, 0, 0.06),
		0px -8px 12px -2px rgba(0, 0, 0, 0.08);
}

.CookieBanner--hidden {
	display: none;
}

.CookieBanner__content {
	display: flex;
	gap: rem(24) rem(36);
	align-items: center;
	justify-content: space-between;

	@include mobile {
		flex-wrap: wrap;
	}
}

.CookieBanner__text-container {
	a {
		color: $c-red-new;
		text-decoration: underline;
		text-underline-offset: rem(3);

		&:hover {
			color: $c-red-new-hover-dark;
		}
	}
}

.CookieBanner__confirmation-button {
	flex: 0 0 auto;
}
