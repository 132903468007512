/* VideoEmbed Component Styles */

.VideoEmbed--inner {
	position: relative;
	overflow: hidden;

	.VideoContent & {
		border-radius: rem(10);
	}
}

.VideoEmbed--image {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $c-black;
		opacity: 0.6;
		z-index: 1;
	}
}

.VideoEmbed--iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	visibility: hidden;
	pointer-events: none;
	z-index: 2;

	iframe {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.VideoEmbed--button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.VideoEmbed--title {
	margin-top: rem(25);

	@include mobile {
		@include font-size(17, 28, true);
	}
}

.VideoEmbed--tracking-notice {
	position: absolute;
	left: $side-padding;
	right: $side-padding;
	bottom: rem(24);
	z-index: 1;
	font-size: rem(20);
	line-height: 1.2;
	text-align: center;
	color: $c-white;

	@include tablet {
		font-size: rem(16);
	}

	@include mobile {
		left: $side-padding-mobile;
		right: $side-padding-mobile;
	}
}

.VideoEmbed.playing {
	.VideoEmbed--iframe {
		visibility: visible;
		pointer-events: auto;
	}
}
