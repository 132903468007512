/* MessageBar Component Styles */
.MessageBar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
}

.MessageBar--inner {
	background: $c-cold-gray-light;
	transform: translateY(100%);
	transition: transform 0.4s ease;

	.MessageBar.in & {
		transform: translateY(0);
	}
}

.MessageBar--content {
	display: flex;
	align-items: center;
	padding: rem(45) 0;

	@include mobile {
		flex-direction: column;
		padding: mobile-rem($side-padding-mobile) 0;
	}
}

.MessageBar--actions {
	flex-shrink: 0;
	margin-left: auto;

	> * {
		margin-right: rem(10);

		&:last-child {
			margin-right: 0;
		}
	}

	@include mobile {
		margin-left: 0;
		width: 100%;
		margin-top: mobile-rem($side-padding-mobile);
	}

	@include mobile-portrait {
		> * {
			margin-right: mobile-rem(10);
			margin-bottom: mobile-rem(10);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.MessageBar--text {
	margin-right: rem(40);

	&:last-child {
		margin-right: 0;
	}

	@include mobile {
		margin-right: 0;
		width: 100%;
		@include font-size(12, 17, true);
	}
}
