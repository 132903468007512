/* SearchPresets Component Styles */
.SearchPresets {
	padding: rem(60) 0 rem(70) 0;
}

.SearchPresets--content {
	@include grid-container;
}

.SearchPresets--inner {
	@include grid-content-standard;
}

.SearchPresets--title {
	@include heading-xs;
	margin-bottom: rem(50);

	@include mobile {
		margin-bottom: mobile-rem(28);
	}
}

.SearchPresets--list {
	@include list-reset;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;

	@include mobile {
		flex-flow: column nowrap;
		justify-content: flex-start;
	}
}

.SearchPresets--item {
	@include mobile {
		margin-bottom: mobile-rem(20);
	}
}

.SearchPresets.disabled {
	display: none;
}
