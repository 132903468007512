/* FilterCard Component Styles */
.FilterPagination {
	display: flex;
	gap: rem(8);
	justify-content: center;
	margin-top: rem(32);
}

.FilterPagination--link,
.FilterPagination--button-prev,
.FilterPagination--button-next {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
	width: rem(36);
	height: rem(36);
	border: 1px solid $c-gray-light-darker-new;
	border-radius: 50%;
	font-size: rem(14);
	color: $c-gray-new-text-light;
	line-height: 1;
	overflow: hidden;

	&:hover {
		color: $c-gray-new-text;
	}
}

.FilterPagination--button-prev,
.FilterPagination--button-next {
	position: relative;

	&::before {
		@include bg-icon-style(chevron-left);

		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: rem(15);
		height: rem(15);
	}
}

.FilterPagination--button-disabled {
	opacity: 0.5;
	pointer-events: none;
}

.FilterPagination--button-prev {
	&::before {
		margin-left: rem(-1);
	}
}

.FilterPagination--button-next {
	&::before {
		mask-image: url(#{icon-encoded(chevron-right)});
		margin-left: rem(1);
	}
}

.FilterPagination--link-active {
	color: $c-gray-new-text;
	font-weight: bold;
	border-color: $c-red-new;
}

.FilterPagination--list {
	display: flex;
	gap: rem(8);
	list-style: none;
}
