/* SearchBar Component Styles */
.SearchBar {
	position: relative;
	width: 100%;
	height: rem(52);
	background: $c-gray-light;
	border-radius: rem(100);
	overflow: hidden;

	&.focused {
		background: $input-gradient;
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border-radius: rem(100);
		background: $c-white;
		border: 1px solid transparent;
		background-clip: padding-box;
		pointer-events: none;
	}

	.Button.SearchBar--clearButton {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: rem(52);
		pointer-events: auto;
		opacity: 1;
		box-sizing: border-box;
		transition: opacity $transition-duration-fast ease-in-out;

		.SvgIcon {
			width: inherit;
		}
	}

	.SearchBar--input:placeholder-shown + .SearchBar--clearButton {
		pointer-events: none;
		opacity: 0;
	}
}

.SearchBar--label {
	position: absolute;
	color: $c-black;
	height: rem(52);
	padding-left: rem(30);
	display: flex;
	flex-flow: column nowrap;
	align-content: center;
	justify-content: center;
	opacity: 0.3;

	.SearchBar.focused & {
		opacity: 1;
	}

	@include mobile {
		padding-left: mobile-rem(17);
	}
}

.SearchBar--label-text {
	display: none;
}

/* Styles that are shared between the rendered text and the search input */
.SearchBar--input,
.SearchBar--rendered-wrapper {
	@include font-size(14);
	line-height: rem(52);
	height: rem(52);
	padding: 0 rem(64);
	width: 100%;
	font-weight: bold;

	@include tablet {
		@include font-size(16);
	}

	@include mobile {
		padding: 0 mobile-rem(43);
	}
}

.SearchBar--input {
	appearance: none;
	border: 0;
	background: transparent;
	position: relative;

	&::placeholder {
		opacity: 0.3;
		color: $c-black;
		font-weight: normal;
	}

	&::-ms-clear {
		display: none;
	}

	.js & {
		caret-color: $c-black;
		color: rgba(0, 0, 0, 0);
	}

	@include ie11 {
		.js .SearchBar.focused & {
			color: $c-black;
		}
	}
}

.SearchBar--rendered-wrapper {
	overflow: hidden;
	display: none;
	@include full-size-absolute;
	pointer-events: none;

	.js & {
		display: block;
	}

	@include ie11 {
		.js .SearchBar.focused & {
			display: none;
		}
	}
}

.SearchBar--rendered {
	white-space: nowrap;
	height: 100%;
	display: block;
	overflow: hidden;

	em {
		font-style: normal;
		opacity: 0.3;
	}
}
