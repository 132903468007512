@use 'sass:math';

/* CardList Component Styles */

.CardList {
	@include component-gap(xl);
}

.CardList--content {
	@include grid-container;
}

.CardList--grid {
	@include grid-container;
	@include list-reset;
}

.CardList--item {
	grid-column: span 4;

	@include mobile {
		grid-column: span 6;
	}

	@include mobile-xs {
		grid-column: span 12;
	}
}

.CardList--pagination {
	@include space(margin-top, l);
}

.CardList.wide {
	.CardList--item {
		grid-column: span 6;

		@include mobile {
			grid-column: span 6;
		}

		@include mobile-xs {
			grid-column: span 12;
		}
	}
}

@include mobile {
	.CardList.collection {
		.CardList--grid {
			margin: 0;
		}

		.CardList--item {
			width: 100%;
		}
	}
}
