.richtext {
	@include richtext;
}

.richtext:not(.plain-links) {
	@include richtext-links(false);
}

.richtext.plain-links {
	@include richtext-links(true);
}
