/* Slider Component Styles */
@import 'swiper';

// .Slider {
// 	@include grid-container;
// }

.Slider--gap {
	position: absolute;
	height: 0;
	width: rem($grid-gap);

	@include mobile {
		width: rem($grid-gap-mobile);
	}
}

.Slider--controls {
	margin-top: rem(15);
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	@include mobile {
		display: none;
	}
}

.Slider--button {
	margin-right: rem(8);
}

.Slider--caption {
	@include font-size(14, 20);
	color: rgba($c-black, 0.5);
	margin: auto rem(21 - 8);
}

.Slider.with-captions .Slider--slide .slide-caption {
	display: none;
}

.Slider--pagination {
	@include title-font;
	@include font-size(16);
	margin-left: auto;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		display: block;
		width: rem(25);
		height: 1px;
		left: 50%;
		top: 50%;
		background: currentColor;
		transform: rotate(-55deg) translateY(-50%) translateX(-50%);
		transform-origin: 0 0;
	}
}

.Slider--pagination-current {
	display: inline-block;
	vertical-align: super;
	text-align: center;
	min-width: 2.5ex;
}

.Slider--pagination-total {
	display: inline-block;
	vertical-align: sub;
	text-align: center;
	min-width: 2.5ex;
}

.Slider--read-more {
	display: none;
}

.Slider--slide-inner {
	transition-duration: inherit;
	transition-delay: inherit;
	transition-timing-function: inherit;
}

.Slider--container {
	.js &:not(.swiper-container-initialized) {
		&,
		& ~ * {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
}

.Slider.buttons-outside {
	@include grid-container;

	grid-column: 1 / span 12;
	max-width: 100%;

	.Slider--button {
		margin-right: 0;
	}

	.Slider--area-left,
	.Slider--area-right {
		display: flex;
		align-items: center;

		@include mobile {
			display: none;
		}
	}

	.Slider--area-left {
		grid-column: 1 / span 1;
		grid-row: 1;
	}

	.Slider--area-right {
		grid-column: 12 / span 1;
		grid-row: 1;
	}

	.Slider--container {
		grid-column: 2 / span 10;
		margin-left: 0;
		margin-right: 0;

		@include mobile {
			width: 100%;
			max-width: 100%;
			min-width: 100%;
		}
	}

	.Slider--pagination {
		margin-left: 0;
	}
}

.Slider.mobile-readmore {
	@include mobile {
		.Slider--gap {
			display: none;
		}

		.swiper-wrapper {
			flex-flow: column nowrap;
		}

		.swiper-slide {
			display: none;
			margin-top: 1em;

			&:first-child {
				display: block;
				margin-top: 0;
			}
		}

		&.show-more {
			.swiper-slide {
				display: block;
			}
		}

		.Slider--read-more {
			margin-top: mobile-rem(24);
			display: block;
		}
	}
}

.Slider.with-captions {
	@include mobile {
		.Slider--controls {
			display: flex;
		}

		.Slider--pagination {
			display: none;
		}

		.Slider--caption {
			order: -1;
			margin: auto auto auto 0;
			padding-right: 2ex;
		}

		.Slider--button {
			margin: 0;

			& + .Slider--button {
				margin-left: mobile-rem(6);
			}
		}
	}
}

.Slider .swiper-container-text-fade .swiper-slide {
	transition-property: opacity, transform, -webkit-transform;
	transition-delay: 0s;
	transition-timing-function: ease-out;

	&.swiper-slide-active {
		transition-timing-function: ease-in;
		transition-delay: 0.3s;
	}
}

.Slider .swiper-container-card-slide {
	&.is-transitioning {
		.swiper-slide {
			transform: scale(0.97);

			@include mobile {
				transform: scale(0.95);
			}
		}
	}

	.swiper-slide {
		transition: transform 0.4s ease-in-out;
		transform: scale(1);
		transform-origin: 0 50%;
	}
}
