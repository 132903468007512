/* CategorySummarySlider Component Styles */

.CategorySummarySlider {
	@include component-gap(xl);
}

.CategorySummarySlider--content {
	@include grid-container;

	@include mobile {
		display: flex;
		flex-direction: column;
		gap: 0;
	}
}

.CategorySummarySlider--inner {
	grid-column: 2 / span 10;
	display: grid;
	grid-template-columns: repeat(11, 1fr);
	gap: rem($grid-gap);

	@include mobile {
		width: 100%;
		margin: 0;
		display: block;
	}
}

.CategorySummarySlider--text-content {
	flex: 1 1 auto;
	grid-column: 1 / span 5;

	@include mobile {
		max-width: none;
	}
}

.CategorySummarySlider--image {
	grid-column: 8 / span 4;
	flex: 0 0 auto;

	@include mobile {
		margin-left: auto;
		margin-right: auto;
		max-width: 45%;

		.Image {
			border-radius: mobile-rem(6);
		}
	}
}

.CategorySummarySlider--title {
	@include title-font;
	@include font-size-1;
	margin-bottom: rem(27);
	font-weight: bold;

	@include mobile {
		@include font-size(34, 40, true);
	}
}

.CategorySummarySlider--text {
	@include font-size-4;

	@include mobile {
		margin-bottom: mobile-rem(60);
	}
}
