@mixin Card--main($variant: large) {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	border-radius: rem(10);
	color: inherit;
	background: $c-cold-gray-light;
	z-index: 1;
	box-sizing: border-box;

	@if ($variant == large) {
		padding: rem(42) rem(30) rem(70) rem(30);
	} @else if($variant == small) {
		padding: rem(30) rem(30) rem(50) rem(30);
	}

	@include mobile {
		border-radius: mobile-rem(6);
		padding: mobile-rem(18) mobile-rem(12) mobile-rem(32) mobile-rem(12);
	}
}

@mixin Card--header {
	order: -1;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: baseline;
	margin-bottom: rem(96);

	@include mobile {
		margin-bottom: mobile-rem(28);
	}
}

@mixin Card--type {
	@include font-size(13);
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	margin-right: 1em;
	line-height: rem(30);
	vertical-align: baseline;

	@include mobile {
		@include font-size(9, 18, true);
	}
}

@mixin Card--subtype {
	@include title-font;
	@include font-size(15);
	line-height: rem(30);
	vertical-align: baseline;

	@include mobile {
		display: none;
	}
}

@mixin Card--footer {
	position: absolute;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	left: rem(30);
	right: rem(30);
	bottom: rem(44);
	z-index: 2;
	transform: translateZ(0);

	@include mobile {
		left: mobile-rem(12);
		right: mobile-rem(12);
		bottom: mobile-rem(16);
	}
}

@mixin Card--badge {
	order: 10;
	margin-left: auto;
	font-style: normal;
	padding: 0 rem(10);
	@include font-size(13);
	line-height: rem(30);
	border-radius: rem(8);
	color: $c-white;
	background: $c-cold-gray;

	@include mobile {
		@include font-size(9, 18, true);
		background: transparent;
		color: $c-cold-gray;
		border: 1px solid currentColor;
	}
}

@mixin CardHoverAnim($childSelectors, $distance: 14) {
	@include no-touch {
		@each $selector in $childSelectors {
			#{$selector} {
				transform: translateY(0);
				transition: transform 0.5s $ease-in-out-cubic;
			}

			&:hover #{$selector} {
				transform: translateY(rem(-$distance));
				transition-timing-function: cubic-bezier(0.405, 0.205, 0.29, 0.995);
				transition-duration: 0.6s;
			}
		}
	}
}
