/* CallToAction Component Styles */
.CallToAction {
	@include component-gap-standard-padding;

	margin-top: rem(112);
	background-color: $c-cold-gray;
	background-image: url('/assets/img/bg-visual-call-to-action.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: white;

	@include mobile {
		margin-top: rem(80);
		background-position: bottom center;
		background-image: url('/assets/img/bg-visual-call-to-action-mobile.png');
	}
}

.CallToAction--content {
	max-width: $max-text-width;
}

.CallToAction--action-list {
	display: flex;
	flex-direction: column;
	gap: rem(10);
	margin: rem(48) 0 0;
	padding: 0;
	font-family: $title-font-family;
	font-size: rem(24);
	list-style: none;

	@include mobile {
		margin-top: rem(36);
		font-size: rem(22);
	}
}

.CallToAction--action-list-item {
	position: relative;
	padding-left: rem(44);

	&::before {
		@include bg-icon-style(check-mark);
		content: '';

		position: absolute;
		top: 0.15em;
		left: 0;
		width: 1.4em;
		height: 1.4em;
	}
}

.CallToAction--cta-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: rem(12);
	margin: rem(48) 0 0;

	@include mobile {
		margin-top: rem(36);
	}
}

.CallToAction--cta--single {
	@include mobile-portrait {
		width: 100%;
	}
}

.CallToAction--secondary-action-text {
	margin-top: rem(24);
	font-size: rem(18);
	color: $c-white;
}

.CallToAction--secondary-action-link {
	color: $c-white;
	text-decoration: underline;
	text-underline-offset: 0.25em;

	&:hover {
		color: $c-gray-lightest;
	}
}
