/* MediaHeader Component Styles */

.MediaHeader {
	@include space(margin-bottom, xxl);
}

.MediaHeader--content {
	@include grid-container;
}

.MediaHeader--inner {
	grid-column: 2 / span 10;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

.MediaHeader--breadcrumbs {
	margin: rem(32) 0 rem(106);

	@include mobile {
		margin-bottom: rem(36);
	}
}

.MediaHeader--title {
	@include coloredText;
	@include hyphenate;
	font-family: $title-font-family;
	font-size: rem(80);
	line-height: 1.2;
	font-weight: bold;

	@include mobile {
		max-width: unset;
		font-size: rem(56);
	}
}

.MediaHeader--suffix {
	@include font-size(14, 20);
	color: $c-gray;
	margin-top: rem(40);

	@include mobile {
		@include font-size(12, 17, true);
		margin-top: mobile-rem(35);
	}
}

.MediaHeader--button {
	margin-top: rem(40);

	@include mobile {
		margin-top: mobile-rem(35);
	}
}

.MediaHeader--media {
	margin-top: rem(80);
}

.MediaHeader--media-audio {
	display: block;
	width: 100%;
	height: auto;
}
