@mixin richtext() {
	font-size: rem(18);

	ul,
	ol {
		@include list-reset;
	}

	p,
	ul,
	ol {
		margin: 0;
		padding: 0;
		margin-bottom: rem(20);

		&:last-child {
			margin-bottom: 0;
		}
	}

	h3,
	h4,
	h5,
	h6 {
		@include h3;
		@include space('margin-top', m);
		@include space('margin-bottom', s);
		font-weight: bold;

		&:first-child {
			margin-top: 0;
		}
	}

	ul {
		li {
			padding-left: rem(26);
			position: relative;
			list-style-type: none;

			&:before {
				position: absolute;
				content: '—';
				left: 0;
			}
		}
	}

	ol {
		counter-reset: list;

		li {
			padding-left: 3.5ex;
			position: relative;
			list-style-type: none;
			margin-bottom: 1em;

			&:before {
				counter-increment: list;
				content: counter(list) '.';
				position: absolute;
				left: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	table {
		@include space(margin-top, s);
		@include space(margin-bottom, s);

		tr {
			border-bottom: 1px solid rgba($c-black, 0.1);
		}

		th {
			@include heading-xs;
			margin: 0;
			padding: rem(18) 0;
			text-align: left;
			color: $c-black;
		}

		td {
			@include font-size(15);
			text-align: left;
			padding: rem(25) 0;
			color: rgba($c-black, 0.5);
		}

		.align-right {
			text-align: right;
		}
	}

	// Allow icons in richtext
	[class^='icon-'],
	[class*=' icon-'] {
		display: inline-block;
		position: relative;
		text-indent: -9999em;
		width: 1em;
		vertical-align: center;

		&:before {
			vertical-align: center;
			position: absolute;
			left: 0;
			text-indent: 0;
		}

		&:empty {
			text-indent: 0;
			width: auto;

			&:before {
				position: static;
			}
		}
	}

	@each $name, $glyph in $icons {
		.icon-#{$name} {
			@include icon($name);
		}
	}
}

@mixin richtext-links($plain: false) {
	@if $plain {
		a {
			color: currentColor;
			border-bottom: 1px solid $c-gray-light;
		}
	} @else {
		a {
			color: $c-red-new;

			&:hover {
				color: $c-red-new-hover-dark;
			}
		}

		a[href$='.pdf'],
		a[href$='.PDF'] {
			@include icon(download, after);

			&:after {
				display: inline;
				color: currentColor;
				margin-left: 0.3ex;
			}
		}
	}
}
