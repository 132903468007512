/* OrganizationCard Component Styles */

.OrganizationCard {
	position: relative;
	height: rem(280);
	color: $c-cold-gray;

	@include CardHoverAnim(('.OrganizationCard--title'), 8);

	@include mobile {
		height: mobile-rem(227);
	}
}

.OrganizationCard--main {
	@include Card--main(small);
	background: $c-cold-gray-light;
	padding-right: rem(265);
}

.OrganizationCard--title {
	@include SmallCard--title;

	@include mobile {
		@include line-clamp(1);
	}
}

.OrganizationCard--subtitle {
	@include SmallCard--subtitle;
	@include line-clamp(1);
}

.OrganizationCard--header {
	@include Card--header;
	margin-bottom: rem(34);

	@include mobile {
		margin-bottom: mobile-rem(23);
	}
}

.OrganizationCard--type {
	@include Card--type;
}

.OrganizationCard--subtype {
	@include Card--subtype;
}

.OrganizationCard--footer {
	@include Card--footer;
	bottom: rem(21);

	@include mobile {
		justify-content: flex-end;
		bottom: mobile-rem(12);
	}
}

.OrganizationCard--actions {
	margin-left: 0;
}

.OrganizationCard--image {
	position: absolute;
	top: calc(50% - #{rem(75)});
	right: rem(60);
	width: rem(150);
	height: rem(150);

	@include mobile {
		position: static;
		width: mobile-rem(76);
		height: mobile-rem(76);
		margin-bottom: mobile-rem(18);
		right: auto;
		top: auto;
		order: -1;
		align-self: center;
	}
}
