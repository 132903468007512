@use 'sass:math';

/*
This is just a listing of the most commonly used text font-sizes.
There are a lot more different font-sizes in the design (especially small sizes, so this file won't give much
of an overview!)
*/

@mixin font-size-0 {
	font-size: rem(76);
	line-height: math.div(88, 76);
}

@mixin font-size-1 {
	font-size: rem(65);
	line-height: math.div(75, 65);
}

@mixin font-size-2 {
	font-size: rem(40);
	line-height: math.div(52, 40);
}

@mixin font-size-3 {
	font-size: rem(24);
	line-height: math.div(34, 24);
}

@mixin font-size-4 {
	font-size: rem(18);
	line-height: math.div(32, 18);
}

@mixin h3 {
	@include font-size-3;
	letter-spacing: rem(-0.4);

	@include mobile {
		@include font-size(22, 27, true);
		letter-spacing: mobile-rem(-0.4);
	}
}

@mixin font-size($size, $line-height: false, $is-mobile: false) {
	@if ($is-mobile) {
		font-size: mobile-rem($size);
	} @else {
		font-size: rem($size);
	}
	@if ($line-height) {
		line-height: math.div(strip-units($line-height), strip-units($size));
	}
}
