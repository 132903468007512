/* Picture Component Styles */

.Picture {
	@include component-gap-smaller;
}

.Picture--content {
	@include grid-container;
}

.Picture--inner {
	grid-column: 4 / span 5;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

.Picture--caption {
	@include font-size(14, 20);
	color: rgba($c-black, 0.5);
	margin-top: rem(14);
}

.Picture.portrait {
	.Picture--inner {
		grid-column: 5 / span 4;

		@include mobile {
			grid-column: 3 / span 8;
		}
	}
}
