
.svg-angle-down-dims {
	width: rem(10px);
	height: rem(10px);
}


.svg-angle-left-dims {
	width: rem(10px);
	height: rem(10px);
}


.svg-angle-right-dims {
	width: rem(10px);
	height: rem(10px);
}


.svg-angle-up-dims {
	width: rem(10px);
	height: rem(10px);
}


.svg-angle-up-big-dims {
	width: rem(10px);
	height: rem(10px);
}


.svg-angle-up-scroll-dims {
	width: rem(36px);
	height: rem(36px);
}


.svg-arrow-left-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-arrow-right-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-arrow-right-large-dims {
	width: rem(36px);
	height: rem(36px);
}


.svg-arrow-up-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-audio-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-checkbox-checked-dims {
	width: rem(18px);
	height: rem(18px);
}


.svg-checkbox-indeterminate-dims {
	width: rem(18px);
	height: rem(18px);
}


.svg-checkbox-unchecked-dims {
	width: rem(18px);
	height: rem(18px);
}


.svg-checkmark-dims {
	width: rem(18px);
	height: rem(18px);
}


.svg-close-dims {
	width: rem(18px);
	height: rem(18px);
}


.svg-close-big-dims {
	width: rem(36px);
	height: rem(36px);
}


.svg-download-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-dropdown-dims {
	width: rem(18px);
	height: rem(18px);
}


.svg-filter-dims {
	width: rem(16px);
	height: rem(16px);
}


.svg-heart-filled-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-heart-outline-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-language-dims {
	width: rem(18px);
	height: rem(18px);
}


.svg-menu-dims {
	width: rem(18px);
	height: rem(18px);
}


.svg-menu-big-dims {
	width: rem(36px);
	height: rem(36px);
}


.svg-minus-dims {
	width: rem(14px);
	height: rem(14px);
}


.svg-plus-dims {
	width: rem(14px);
	height: rem(14px);
}


.svg-profile-dims {
	width: rem(18px);
	height: rem(18px);
}


.svg-remove-dims {
	width: rem(16px);
	height: rem(16px);
}


.svg-reset-dims {
	width: rem(16px);
	height: rem(16px);
}


.svg-search-dims {
	width: rem(18px);
	height: rem(18px);
}


.svg-search-new-dims {
	width: rem(20px);
	height: rem(20px);
}


.svg-search-small-dims {
	width: rem(18px);
	height: rem(18px);
}


.svg-secure-dims {
	width: rem(16px);
	height: rem(17px);
}


.svg-share-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-social-email-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-social-facebook-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-social-instagram-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-social-linkedin-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-social-twitter-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-social-youtube-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-spinner-dims {
	width: rem(100px);
	height: rem(100px);
}


.svg-type-article-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-type-audio-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-type-bag-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-type-event-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-type-image-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-type-knowledge-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-type-organisation-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-type-strategy-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-type-video-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-type-weekly-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-view-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-view-card-dims {
	width: rem(26px);
	height: rem(26px);
}


.svg-view-list-dims {
	width: rem(26px);
	height: rem(26px);
}

