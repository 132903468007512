/* LargeTeaser Component Styles */
.MultipleTeaser {
	@include component-gap-small;
}

.MultipleTeaser--wrapper {
	.BigCard {
		flex: 0 0 auto;
		$width: calc(50% - #{rem(24)});
		width: $width;
		max-width: $width;
		min-width: $width;

		@include tablet-portrait {
			width: 100%;
			max-width: none;
			min-width: 0;
		}
	}
}

.MultipleTeaser--card-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: rem($grid-gap);
	margin-top: rem(24);

	@include mobile {
		margin-top: rem(18);
		gap: rem($grid-gap-mobile);
	}
}

.MultipleTeaser--three-per-row {
	.BigCard {
		$width: calc(33.33% - #{rem(32)});
		width: $width;
		max-width: $width;
		min-width: $width;

		@include tablet-portrait {
			width: 100%;
			max-width: none;
			min-width: 0;
		}
	}
}
