/* ModuleTitle Component Styles */

.ModuleTitle {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: rem(51);

	@include mobile {
		flex-flow: column nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		margin-bottom: mobile-rem(28);
	}
}

.ModuleTitle--title {
	// @include hyphenate; TODO: Repimlement after design change
	// @include coloredText;
	@include title-font;
	@include font-size-1;
	font-weight: bold;

	@include mobile {
		@include font-size(34, 40, true);
		margin-bottom: mobile-rem(10);

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.ModuleTitle--byline {
	@include font-size-3;
	font-style: normal;
	margin-left: auto;
	margin-right: rem(20);

	&:last-child {
		margin-right: 0;
	}

	@include mobile {
		display: block;
		@include font-size(16, 19, true);
		margin: 0;
	}
}

.ModuleTitle.cta {
	justify-content: flex-start;

	@include above-mobile {
		.ModuleTitle--title {
			margin-right: rem(25);
		}
	}
}
