/* FactsAndFigures Component Styles */
.FactsAndFigures {
	@include component-gap-standard;
}

.FactsAndFigures--list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: rem(80);
	margin-top: rem(40);
	list-style: none;

	@include mobile {
		grid-template-columns: repeat(1, 1fr);
		gap: rem(36);
		margin-top: rem(36);
	}
}

.FactsAndFigures--fact-text {
	display: flex;
	flex-direction: column;
	font-family: $title-font-family;
	color: $c-gray-new-text-light;
}

.FactsAndFigures--value {
	position: relative;
	padding-bottom: rem(72);
	font-size: rem(72);
	line-height: 1.2;

	@include mobile {
		padding-bottom: rem(48);
		font-size: rem(48);
	}

	&::before {
		content: '';
		position: absolute;
		left: rem(24);
		bottom: rem(4);
		height: rem(68);
		border-left: 1px solid currentColor;

		@include mobile {
			left: rem(12);
			height: rem(44);
		}
	}
}

.FactsAndFigures--text {
	font-size: rem(24);

	@include mobile {
		font-size: rem(22);
	}
}

.FactsAndFigures--sources {
	margin-top: rem(40);

	@include mobile {
		margin-top: rem(36);
	}
}
