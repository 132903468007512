@use 'sass:math';

/* Image Component Styles */

.Image {
	display: block;
	width: 100%;
	height: 0;
	user-select: none;
	position: relative;
	overflow: hidden;
}

.Image--img[data-object-fit-polyfilled] {
	@include visuallyhidden;
}

.Image--img {
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.Image.circle {
	border-radius: rem(200);
	border: 1px solid $c-gray-light;
	background: $c-white;
}

// preset sizes
.Image.default {
	padding-bottom: math.div(100%, 2200) * 1198;
}

.Image.landscape {
	padding-bottom: math.div(100%, 1060) * 780;
}

.Image.portrait {
	padding-bottom: math.div(100%, 832) * 1131;
}

.Image.landscapeSmall {
	padding-bottom: math.div(100%, 536) * 394;
}

.Image.portraitSmall {
	padding-bottom: math.div(100%, 456) * 620;
}

.Image.mediaSquare,
.Image.smallCardImage {
	padding-bottom: 100%;
}

.Image.disabled {
	pointer-events: none;
}
