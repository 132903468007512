$base-rem-size: 16px;
$base-font-size: 18px;
$base-line-height: calc(32 / 18);
$base-font-color: $c-black;
$base-font-family: 'Frutiger Neue', sans-serif;
$title-font-family: 'Frutiger Serif', serif;
// Rem scaling on mobile
$mobile-scale: 0.81;

// Transition durations
$transition-duration-very-slow: 1s;
$transition-duration-slow: 0.5s;
$transition-duration-default: 0.3s;
$transition-duration-fast: 0.2s;

// Easings
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
