/* CategoryOverview Component Styles */
.CategoryOverview {
	@include component-gap-small;
}

.CategoryOverview--list-container {
	display: flex;
	flex-direction: column;
	gap: rem(48);
	margin-top: rem(36);

	@include mobile {
		gap: rem(36);
		margin-top: rem(24);
	}
}

.CategoryOverview--list-title {
	@include coloredText;

	margin-bottom: rem(16);
	font-size: rem(20);
	font-weight: bold;
	line-height: 1.2;
}
