@use 'sass:math';

html {
	text-size-adjust: 100%;

	// We assume browser default font size is 16px. Also set $base-font-size to 16px for correct rem calculations.
	font-size: 100%;
	box-sizing: border-box;
	-ms-overflow-style: -ms-autohiding-scrollbar;

	// > 1440
	@include above-desktop {
		font-size: 100%;
	}

	// <= 1440
	@include desktop {
		font-size: math.round(math.div(100%, $site-width) * 1200px);
	}

	@include tablet {
		font-size: math.round(math.div(100%, $site-width) * 1000px);
	}

	@include mobile {
		font-size: 100% * $mobile-scale;
	}

	/*
	@media screen and (min-aspect-ratio: 16/10) {
		font-size: 80%;

		@include desktop {
			font-size: 80% / $site-width * 1200px;
		}

		@include tablet {
			font-size: 80% / $site-width * 1000px;
		}

		@include tablet-portrait {
			font-size: 80% / $site-width * 750px;
		}
	}
	 */
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

html,
body {
	min-height: 100vh;
	height: auto;
	width: 100%;
}

body {
	font-family: $base-font-family;
	color: $base-font-color;
	@include font-size-4;
	@include textantialiasing;
	display: flex;
	flex-flow: column nowrap;

	&.fixed {
		overflow: hidden;
	}

	@include mobile {
		@include font-size(17, 28, true);
	}
}

::-moz-selection {
	@include text-selection;
}

::selection {
	@include text-selection;
}

a {
	color: inherit;
	text-decoration: none;
}

button {
	appearance: none;
	border: 0;
	background: none;
	cursor: pointer;
}

input,
select,
textarea {
	appearance: none;
}

h1 {
	margin: 0; // reset margin set by normalize.css
}

b,
strong {
	font-weight: bold;
}

i,
em {
	font-style: italic;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}
