/* FilterCardMedia Component Styles */
.FilterCardMedia {
	display: block;
}

.FilterCardMedia--figure {
	display: flex;
	flex-direction: column;
}

.FilterCardMedia--image-wrapper {
	position: relative;
	overflow: hidden;
	background-color: $c-gray-light-new;
	border-radius: rem(16);
	padding-top: 100%;
	height: 0;
	object-fit: cover;

	@include mobile {
		border-radius: mobile-rem(12);
	}
}

.FilterCardMedia--image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.FilterCardMedia--caption {
	margin-top: rem(8);

	@include mobile {
		font-size: rem(15);
	}
}
