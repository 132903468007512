/* AnimatedBackdrop Component Styles */
.AnimatedBackdrop {
	--bg-x: 0.5;
	--bg-y: 0.5;
	@include full-size-absolute;
	@include AnimatedBackdrop();
	@include inline-keyvisual2;
}

.AnimatedBackdrop.with-text {
	position: static;
	background: transparent;
	color: $c-red;

	@supports (background-clip: text) {
		@include AnimatedBackdrop(rem(400));
		color: transparent;
		background-clip: text;
	}
}
