/* SignInForm Component Styles */

.SignInForm {
	@include space(margin-top, xl);
	@include space(margin-bottom, xxl);
}

.SignInForm--content {
	@include grid-container;
}

.SignInForm--inner {
	grid-column: 4 / span 6;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

.SignInForm--heading {
	@include coloredText;
	@include title-font;
	@include font-size-1;
	margin-bottom: rem(30);
	font-weight: bold;

	@include mobile {
		@include font-size(44, 50, true);
		margin-bottom: mobile-rem(25);
	}
}

.SignInForm--suffix {
	display: flex;
	justify-content: space-between;
}

.SignInForm--suffix-link {
	display: block;
	@include font-size(14);
	margin-left: rem(20);
	margin-bottom: rem(40);
	opacity: 0.6;
	transition: opacity $transition-duration-default ease-in-out;

	@include no-touch {
		&[href]:hover {
			opacity: 1;
		}
	}

	@include mobile-portrait {
		margin-left: 0;
	}
}

.SignInForm--button {
	display: flex;
	align-items: center;
	margin-top: rem(60);

	@include mobile {
		margin-top: mobile-rem(30);
	}

	@include mobile-portrait {
		justify-content: space-between;
	}
}

.SignInForm--button-link {
	@include font-size(14);
	margin-left: rem(30);
	@include icon(angle-right, after);
	color: $c-red-new;

	&:hover {
		color: $c-red-new-hover-dark;
	}

	&:after {
		display: inline;
		color: currentColor;
		margin-left: rem(5);
		font-size: 80%;
	}
}

.SignInForm--error {
	margin-bottom: rem(60);
	@include font-size(14);
	list-style: none;

	@include mobile {
		margin-bottom: mobile-rem(30);
	}
}

.SignInForm--error-text {
	margin-bottom: rem(20);
	position: relative;
	padding-left: rem(31);

	&:before {
		content: '!';
		position: absolute;
		top: rem(2);
		left: 0;
		border-radius: 50%;
		border: 1px solid $c-red;
		@include font-size(13);
		font-weight: bold;
		width: rem(21);
		height: rem(21);
		line-height: rem(18);
		text-align: center;
	}

	& + .SignInForm--error-text {
		margin-top: rem(-10);
	}
}
