/* CategoriesBar Component Styles */
.CategoriesBar {
	margin: rem(25) 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	@include mobile {
		margin: mobile-rem(20) 0 0 0;
	}
}

.CategoriesBar--scroll-container {
	flex: 1 1 auto;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	// stylelint-disable
	.js & {
		overflow: auto;
		scrollbar-width: none;
		overflow: -moz-scrollbars-none;
		-ms-overflow-style: none;
		overflow-y: hidden;

		.TagList {
			display: inline-flex;
			flex-flow: row nowrap;
			margin: 0 rem($side-padding);
		}

		@include mobile {
			.TagList {
				margin: 0 rvw($side-padding-mobile, $site-width-mobile);
			}
		}
	}
}

.CategoriesBar--controls {
	padding-right: rem($side-padding);
	position: relative;

	flex: 0 0 0px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	background: $c-white;

	@include mobile {
		visibility: hidden;
		display: none;
	}

	&::before {
		content: '';
		width: rem($side-padding);
		height: 100%;

		position: absolute;
		right: 100%;
		top: 0;

		background: linear-gradient(90deg, rgba($c-white, 0) 0%, $c-white 50%);
	}
}

.CategoriesBar--button {
	&:first-child {
		display: none;
		visibility: hidden;
	}

	&.is-visible {
		visibility: visible;
		display: block;
	}
	&.is-hidden {
		visibility: hidden;
		display: none;
	}

	& + & {
		margin-left: 5px;
	}
}
