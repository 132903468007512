/* ProfileTable Component Styles */
.ContactProtected {
	@include component-gap-small;
}

.ContactProtected--content {
	@include grid-container;
}

.ContactProtected--content-inner-wrapper {
	@include grid-content-standard;
}

.ContactProtected--contacts {
	display: flex;
	flex-direction: column;
	gap: rem(20);
	margin-top: rem(20);

	@include mobile {
		gap: rem(18);
		margin-top: rem(18);
	}
}

.ContactProtected--contact {
	display: flex;
	flex-direction: column;
	gap: rem(6);
}

.ContactProtected--contact-title {
	font-size: rem(18);
	line-height: 1.4;
}
