/* Sources Component Styles */
.Sources {
	display: flex;
	flex-wrap: wrap;
	font-size: rem(16);
	color: $c-gray-new-text-light;

	@include mobile {
		font-size: rem(14);
	}
}

.Sources--list {
	display: inline-flex;
	flex-wrap: wrap;
	list-style: none;
}

.Sources--list-item {
	display: inline-flex;
}

.Sources--list-link {
	color: $c-gray-new-text-light;
	text-decoration: underline;
	text-underline-offset: 0.25em;

	&:hover {
		color: $c-gray-new-text;
	}
}
