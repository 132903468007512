/* ContextActions Component Styles */
.ContextActions {
	@include list-reset;
	display: flex;
	flex-flow: row nowrap;
}

.ContextActions--item {
	margin-right: rem(8);

	&:last-child {
		margin-right: 0;
	}

	&.right:last-child {
		margin-left: auto;
	}
}

.ContextActions.small {
	margin-left: rem(-2);
	margin-right: rem(-2);

	.ContextActions--item {
		margin-right: rem(4);

		&:last-child {
			margin-right: 0;
		}
	}

	@include mobile {
		margin: 0;

		.ContextActions--item {
			height: mobile-rem(16);
		}
	}
}

.ContextActions.vertical {
	@include above-mobile {
		flex-direction: column;

		.ContextActions--item {
			margin-right: 0;
			margin-bottom: rem(8);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
