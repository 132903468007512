/* ShareAction Component Styles */

.ShareAction {
	position: relative;

	.Button {
		display: block;
	}

	.SvgIcon {
		max-width: rem(22);
	}
}

.ShareAction--toggle {
	appearance: none;
	border: 0;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	width: 0;
	height: 0;

	// Block screen on non-js devices. Any click will then close the dialog
	&:checked {
		position: fixed;
		width: 100%;
		z-index: 2;
	}

	// Closing is done via JS
	.js &:checked {
		position: absolute;
		width: 0;
	}

	&:checked + .ShareAction--close {
		display: block;
		pointer-events: none;
	}

	&:checked + .ShareAction--close + .Button {
		opacity: 0;
		z-index: 5;
		position: relative;
	}

	&:checked + .ShareAction--close + .Button + .ShareAction--sharers {
		display: flex;
		z-index: 4;
	}
}

.ShareAction--close {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	padding: 0 rem(16);
	box-sizing: content-box;
	display: none;
	z-index: 5;
}

.ShareAction--sharers {
	@include list-reset;
	flex-flow: row nowrap;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	border: 1px solid $c-gray-light;
	border-radius: rem(100);
	padding: 0 rem(5) 0 rem(45);
	display: none;
	background: $c-white;

	.ShareAction--item .Button {
		width: rem(48);
		height: rem(48);
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
