/* ProtectedField Component Styles */
.ProtectedField {
	display: flex;
	gap: rem(9);
	font-size: rem(16);
}

.ProtectedField--protected {
	color: $c-gray-new-text-disabled;
}

.ProtectedField--icon {
	flex: 0 0 1em;
	margin-top: 0.45em;
	width: 1em;
	height: 1em;

	@include mobile {
		margin-top: 0.35em;
	}
}

.ProtectedField--link {
	color: $c-red-new;
	text-decoration: underline;
	text-underline-offset: rem(3);

	&:hover {
		color: $c-red-new-hover-dark;
	}
}
