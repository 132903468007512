/* DetailPageHeader Component Styles */
.DetailPageHeader {
	margin-bottom: rem(80);

	@include mobile {
		margin-bottom: rem(56);
	}
}

.DetailPageHeader--breadcrumbs {
	grid-column: 1 / span 12;
	margin: rem(32) 0 rem(80);

	@include mobile {
		margin-bottom: rem(60);
	}

	.EntryPageHeader--only-breadcrumbs & {
		margin-bottom: 0;
	}
}

.DetailPageHeader--main {
	@include grid-container;
	gap: 0 rem($grid-gap);

	@include mobile {
		gap: 0;
	}
}

.DetailPageHeader--text-wrapper {
	@include grid-content-standard;
}

.DetailPageHeader--author {
	margin-bottom: rem(20);

	@include mobile {
		margin-bottom: rem(18);
	}
}

.DetailPageHeader--title,
.DetailPageHeader--lead {
	font-family: $title-font-family;
}

.DetailPageHeader--title {
	@include coloredText;
	@include hyphenate;

	font-size: rem(80);
	line-height: 1.2;

	@include mobile {
		font-size: rem(56);
	}
}

.DetailPageHeader--lead {
	margin-top: rem(20);
	font-size: rem(24);
	line-height: 1.4;

	@include mobile {
		margin-top: rem(18);
		font-size: rem(22);
	}
}

.DetailPageHeader--byline {
	display: block;
	margin-top: rem(20);
	font-size: rem(14);
	color: rgba($c-black, 0.5);

	@include mobile {
		margin-top: rem(18);
		font-size: rem(12);
	}
}

.DetailPageHeader--image-wrapper {
	grid-column: 11 / span 2;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

.DetailPageHeader--image-container {
	position: relative;
	padding-bottom: 100%;
	overflow: hidden;
}

.DetailPageHeader--image {
	position: absolute;
	inset: 0;
	height: 100%;
	width: 100%;
	margin-top: 0;
}

.DetailPageHeader--image-caption {
	@include hyphenate;
	margin-top: rem(14);
	font-size: rem(12);
	color: rgba($c-black, 0.5);

	@include mobile {
		font-size: rem(14);
	}
}

.DetailPageHeader--with-image {
	.DetailPageHeader--main {
		position: relative;

		@include mobile {
			display: flex;
			flex-direction: column;
		}
	}

	.DetailPageHeader--text-wrapper {
		flex: 1 1 auto;
		grid-column: 1 / span 9;

		@include mobile {
			width: 100%;
		}
	}

	.DetailPageHeader--image-wrapper {
		margin-top: rem(12);
		grid-column: 10 / span 3;

		@include mobile {
			flex: 1 0 100%;
			width: 100%;
			max-width: 100%;
			display: flex;
			flex-direction: column;
			margin: rem(32) 0 0;
			order: -1;
		}
	}
}

.DetailPageHeader--with-author {
	.DetailPageHeader--image-wrapper {
		margin-top: rem(94);

		@include mobile {
			margin-top: rem(32);
		}
	}
}
