/* Tag Component Styles */
.Tag {
	position: relative;
	display: inline-block;
	line-height: rem(52);
	padding: 0 rem(30) rem(2) rem(30);
	font-weight: normal;
	@include font-size(14);
	color: $c-black;
	border-radius: 100px;
	overflow: hidden;
	white-space: nowrap;
	background: $c-cold-gray-light;
	transition: color $transition-duration-default ease-in-out;
	transform: translate3d(0, 0, 0);

	.Tag--bg {
		opacity: 0;
		transition: opacity $transition-duration-default ease-in-out;
		transform: translate3d(0, 0, 0);
	}

	@include no-touch {
		&:hover {
			color: $c-white;

			.Tag--bg {
				opacity: 1;
			}
		}
	}

	@include oldEdge(true) {
		transition: color $transition-duration-default ease-in-out,
			background-color $transition-duration-default ease-in-out;

		.Tag--bg {
			display: none;
		}

		&:hover {
			background-color: $c-red;
		}
	}

	@include mobile {
		padding: 0 mobile-rem(18) rem(2) mobile-rem(18);
		line-height: mobile-rem(40);
	}
}

.Tag--label {
	position: relative;
	z-index: 1;
	pointer-events: none;
}

// -------------------------------------------------
// Modifiers

.Tag.small {
	@include font-size(11);
	line-height: rem(30);
	padding: 0 rem(15);

	@include mobile {
		@include font-size(11, 30, $is-mobile: true);
	}
}
