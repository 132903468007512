@use 'sass:map';

$spaces: (
	xxl: 190px,
	xl: 135px,
	l: 95px,
	m: 50px,
	s: 25px,
);

$spaces-tablet: (
	xxl: 126px,
	xl: 90px,
	l: 63px,
	m: 35px,
	s: 12px,
);

$spaces-mobile: (
	xxl: 80px,
	xl: 80px,
	l: 50px,
	m: 35px,
	s: 12px,
);

$max-text-width: rem(912);

@mixin space($prop, $size, $negative: false) {
	#{$prop}: rem(map.get($spaces, $size) * (if($negative, -1, 1)));

	@include tablet {
		#{$prop}: rem(map.get($spaces-tablet, $size) * (if($negative, -1, 1)));
	}

	// Use mobile spacings already on tablet-portrait
	@include mobile {
		#{$prop}: mobile-rem(
			map.get($spaces-mobile, $size) * (if($negative, -1, 1))
		);
	}
}

@mixin component-gap($size: xxl) {
	@include space('margin-top', $size);
	@include space('margin-bottom', $size);
}

@mixin component-gap-standard {
	margin-top: rem(112);
	margin-bottom: rem(112);

	@include mobile {
		margin-top: rem(80);
		margin-bottom: rem(80);
	}
}

@mixin component-gap-small {
	margin-top: rem(80);
	margin-bottom: rem(80);

	@include mobile {
		margin-top: rem(56);
		margin-bottom: rem(56);
	}
}

@mixin component-gap-smaller {
	margin-top: rem(60);
	margin-bottom: rem(60);
}

@mixin component-gap-smallest {
	margin-top: rem(20);
	margin-bottom: rem(20);
}

@mixin component-gap-standard-padding {
	padding-top: rem(112);
	padding-bottom: rem(112);

	@include mobile {
		padding-top: rem(80);
		padding-bottom: rem(80);
	}
}

@mixin component-gap-small-padding {
	padding-top: rem(80);
	padding-bottom: rem(80);

	@include mobile {
		padding-top: rem(56);
		padding-bottom: rem(56);
	}
}
