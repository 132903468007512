.color-list {
	@include list-reset;

	max-width: 800px;
}

.color-list__item {
	position: relative;
	float: left;
	min-width: 200px;
	text-align: center;
	margin-bottom: rem(32);
}

.color-list__sample {
	position: relative;
	margin-bottom: rem(12);

	&::before {
		display: block;
		content: '';
		width: 100%;
		padding-top: 100%;
	}
}
