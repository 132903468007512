@use 'sass:math';

$border-color: rgba($c-gray-light-darker-new, 0.5);

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Search Component Styles */
.Search {
	@include component-gap-small;
}

.Search--title-container,
.Search--content {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 0 rem(48);

	@include mobile {
		gap: 0 rem(12);
	}
}

.Search--title-container {
	margin: 0 0 rem(48);

	@include mobile {
		margin-bottom: rem(36);
	}
}

.Search--title,
.Search--lead {
	grid-column: 4 / 13;
	font-family: $title-font-family;

	@include mobile {
		grid-column: 1 / 13;
	}
}

.Search--title {
	@include hyphenate;
	@include coloredText;

	margin: 0;
	font-size: rem(80);
	font-weight: 500;
	line-height: 1.2;
	color: $c-gray-new-text;

	@include mobile {
		font-size: rem(56);
	}
}

.Search--title-query {
	&::before,
	&::after {
		content: '"';
	}

	&:empty {
		display: none;
	}
}

.Search--form {
	grid-column: 4 / 13;
	display: flex;
	gap: rem(24);
	margin-bottom: rem(48);

	@include mobile {
		flex-direction: column;
		grid-column: 1 / 13;
		margin-bottom: rem(36);
	}
}

.Search--form-input {
	height: rem(48);
	padding: rem(6) rem(24) rem(6) rem(24);
	border: 1px solid $border-color;
	border-radius: rem(21);
	flex: 1 0 auto;

	@include mobile {
		min-width: 100%;
	}
}

.Search--results-section {
	position: relative;
	display: flex;
	flex-direction: column;
	grid-column: 4 / 13;
	min-height: rem(240);
	scroll-margin-top: rem(180);

	@include mobile {
		grid-column: 1 / 13;
	}
}

.Search--results-section[hidden] {
	display: none;
}

.Search--results-loading-overlay {
	position: absolute;
	inset: 0;
	background-color: $c-white;
	z-index: 2;
	display: none;

	.Search--loading & {
		display: block;
	}
}

.Search--results-loading-overlay-sticky {
	position: sticky;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	max-height: rem(520);
	top: 0;

	@include mobile {
		max-height: rem(320);
	}

	&::after {
		content: '';
		width: rem(120);
		height: rem(120);
		background-image: url(#{icon-encoded(spinner)});
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		animation: rotating 2s linear infinite;
	}
}

.Search--results-heading-container {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&[hidden] {
		display: none;
	}
}

.Search--results-title,
.Search--results-overview-text {
	color: $c-gray-new-text-light;
	font-size: rem(18);

	&:empty {
		display: none;
	}
}

.Search--results {
	display: flex;
	flex-direction: column;
	gap: rem(32);
	margin-top: rem(16);
}

.Search--no-results-title {
	font-size: rem(28);
}
