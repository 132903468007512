@use 'sass:math';

@import 'susy';

// Grid settings
$site-width: 1392px;
$site-width-mobile: 375px;
$gutter: 40px;
$gutter-mobile: 10px;
$grid-columns: 12;
$grid-gap: 48px;
$grid-gap-mobile: 18px;
$column-width: 74px;
$column-width-mobile: 19px;
// prettier-ignore
$side-padding: 32px;
// prettier-ignore
$side-padding-mobile: 16px;

@function rvw($width, $base-width: $site-width) {
	@return math.div(100vw, $base-width) * $width;
}

$susy: (
	columns: susy-repeat($grid-columns, rvw($column-width)),
	gutters: rvw($gutter),
	math: static,
	global-box-sizing: border-box,
);

$susy-mobile: (
	columns:
		susy-repeat($grid-columns, rvw($column-width-mobile, $site-width-mobile)),
	gutters: rvw($gutter-mobile, $site-width-mobile),
	math: static,
	global-box-sizing: border-box,
);

// Grid mixins
@mixin grid-container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: rem($grid-gap);

	@include mobile {
		gap: rem($grid-gap-mobile);
	}
}

@mixin grid-content-standard {
	grid-column: 3 / span 8;

	@include mobile {
		grid-column: 1 / span 12;
	}
}
