/* Avatar Component Styles */
.Avatar--initials {
	padding-bottom: 100%;
	border-radius: rem(200);
	border: 1px solid $c-gray-light;
	position: relative;
}

.Avatar--initials-inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		display: block;
		width: 100%;
		height: 100%;
		text-align: center;
		@include font-size-4;
		font-weight: bold;
		position: relative;
	}
}

.Avatar--initials-text {
	@include gradient-link($addHover: false);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

// Variants
.Avatar.no-border {
	.Avatar--initials {
		border: 0;
	}

	.Image.circle {
		border: 0;
	}
}
