/* Input Component Styles */

.Input {
	margin: 0;
	padding: 0;
	border: 0;
	margin-bottom: rem(40);

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&.has-error {
		.Input--error {
			display: block;
		}
	}
}

.Input--wrapper {
	position: relative;

	legend.Input--label + & {
		padding-left: rem(20);
	}
}

.Input--label {
	display: flex;
	@include font-size(14);
	margin-bottom: rem(15);

	fieldset.Input & {
		margin-bottom: rem(35);
	}
}

.Input--label-tooltip {
	margin-left: auto;

	&:before {
		display: block;
		content: '?';
		margin-top: rem(2);
		border-radius: 50%;
		border: 1px solid $c-gray-light;
		@include font-size(13);
		font-weight: bold;
		width: rem(21);
		height: rem(21);
		line-height: rem(21);
		text-align: center;
		cursor: help;
	}
}

.Input--select,
.Input--input {
	display: block;
	width: 100%;
	@include font-size(16);
	line-height: calc(25 / 16);
	min-height: rem(56);
	max-height: rem(400);
	padding: rem(15) rem(20);
	border-radius: rem(10);
	background: none;
	position: relative;
	appearance: none;
	z-index: 1;
	resize: vertical;
	border: 1px solid transparent;
	box-shadow: inset 0 0 0 rem(200) $c-white;

	&::-webkit-input-placeholder {
		color: rgba($c-black, 0.5);
	}

	&::-moz-placeholder {
		color: rgba($c-black, 0.5);
	}

	&:-ms-input-placeholder {
		color: rgba($c-black, 0.5);
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		appearance: none;
		margin: 0;
	}

	&::-ms-clear {
		display: none;
	}

	&::-ms-expand {
		display: none;
	}

	&::-webkit-calendar-picker-indicator {
		display: none;
	}

	&::-webkit-clear-button {
		display: none;
	}
}

.Input--checkbox,
.Input--radio {
	opacity: 0;
	position: absolute;
	top: rem(8);
	left: 0;
	width: rem(20);
	height: rem(20);
	cursor: pointer;
}

.Input--option {
	position: relative;
	margin-bottom: rem(20);

	&:last-child {
		margin-bottom: 0;
	}
}

.Input--border {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: rem(10);
	background: $c-gray-light;

	.Input--select:focus + &,
	.Input--input:focus + & {
		background: $input-gradient;
	}

	.Input.has-error & {
		background: $c-red;
	}

	.Input--checkbox + .Input--option-label + &,
	.Input--radio + .Input--option-label + & {
		top: rem(8);
		right: auto;
		bottom: auto;
		width: rem(20);
		height: rem(20);
		border-radius: rem(4);
		pointer-events: none;
		@include icon(checkmark, after);

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			border: 1px solid transparent;
			box-shadow: inset 0 0 0 rem(20) $c-white;
			border-radius: rem(4);
		}

		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 100%;
			transform: translate(-50%, -50%);
			z-index: 1;
			opacity: 0;
			line-height: rem(21);
		}
	}

	.Input--radio + .Input--option-label + & {
		border-radius: rem(100);

		&:before {
			border-radius: rem(100);
		}

		&:after {
			content: '';
			background: $c-black;
			width: rem(6);
			height: rem(6);
			border-radius: rem(100);
		}
	}

	.Input--checkbox:checked + .Input--option-label + &,
	.Input--radio:checked + .Input--option-label + & {
		&:after {
			opacity: 1;
		}
	}

	/*@include no-touch {
		.Input--option-label:hover + & {
			background: $input-gradient;
		}
	}*/
}

.Input--option-label {
	@include font-size(14px);
	padding-left: rem(35);
	cursor: pointer;
	user-select: none;
}

.Input--select {
	padding-right: rem(32);
	text-align: left;
}

.Input--selectIcon {
	position: absolute;
	right: rem(20);
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
	z-index: 1;
}

.Input--error {
	display: none;
	@include font-size(14);
	margin-top: rem(10);
	position: relative;
	padding-left: rem(31);

	&:before {
		content: '!';
		position: absolute;
		top: rem(2);
		left: 0;
		border-radius: 50%;
		border: 1px solid $c-red;
		@include font-size(13);
		font-weight: bold;
		width: rem(21);
		height: rem(21);
		line-height: rem(21);
		text-align: center;
	}

	legend.Input--label + .Input--wrapper + & {
		margin-top: rem(30);
	}
}

.Input--button {
	margin-top: rem(100);

	@include mobile {
		margin-top: mobile-rem(50);
	}
}
