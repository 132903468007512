/* ModuleLead Component Styles */

.ModuleLead {
	display: flex;
	flex-direction: column;
	gap: rem(20);

	@include mobile {
		gap: rem(12);
	}
}

.ModuleLead--title,
.ModuleLead--lead {
	font-family: $title-font-family;
}

.ModuleLead--title-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: rem(20);

	@include mobile {
		flex-direction: column;
		gap: rem(12);
	}
}

.ModuleLead--title {
	@include hyphenate;
	@include coloredText;

	width: 100%;
	font-size: rem(56);
	line-height: 1.2;

	@include mobile {
		font-size: rem(36);
	}
}

.ModuleLead--cta {
	@include mobile {
		margin-bottom: rem(12);
	}
}

.ModuleLead--lead {
	font-size: rem(24);

	@include mobile {
		font-size: rem(22);
	}
}

.ModuleLead.violet-red {
	.ModuleLead--title {
		background-color: $c-red-new-soft;
		background-image: $g-violet-red;
	}
}

.ModuleLead.inverted {
	.ModuleLead--title {
		background-color: $c-white;
		background-image: unset;
	}
}

.ModuleLead.small {
	.ModuleLead--title {
		font-size: rem(40);

		@include mobile {
			font-size: rem(36);
		}
	}
}
