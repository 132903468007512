/* BigCard Component Styles */
.BigCard {
	display: flex;
	flex-direction: row;
	color: $c-gray-new-text;
}

.BigCard--inner {
	width: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	height: 100%;
	padding: rem(64) rem(64) rem(54);
	border: 1px solid transparent;
	border-radius: rem(16);
	background: linear-gradient(white, white) padding-box,
		$g-violet-turqoise border-box;
	transform: translate3d(0, 0, 0);

	@include mobile {
		border-radius: rem(12);
		padding: rem(24) rem(24) rem(18);
	}

	a.BigCard:hover & {
		box-shadow: $box-shadow-soft;
	}
}

.BigCard--content {
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	max-width: $max-text-width;
	height: 100%;
}

.BigCard--title {
	@include title-font;
	font-size: rem(32);
	line-height: 1.2;
	font-weight: bold;

	@include mobile {
		font-size: rem(24);
	}
}

.BigCard--text {
	margin-top: rem(12);
	font-size: rem(18);

	@include mobile {
		font-size: rem(15);
	}
}

.BigCard--cta-wrapper {
	margin-top: auto;
	padding-top: rem(48);

	@include mobile {
		padding-top: mobile-rem(24);
	}
}
