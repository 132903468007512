/* AtOneGlance Component Styles */
.AtOneGlance {
	@include component-gap-standard-padding;

	background-color: $c-gray-light-new;
}

.AtOneGlance--list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: rem(24) rem(48);
	margin-top: rem(80);
	list-style: none;

	@include mobile {
		grid-template-columns: repeat(1, 1fr);
		gap: rem(12);
		margin-top: rem(36);
	}
}

.AtOneGlance--list-item {
	height: 100%;
}

.AtOneGlance--item-link {
	@include transitionEffect(box-shadow);

	display: flex;
	align-items: center;
	padding: rem(28) rem(32);
	gap: rem(28);
	height: 100%;
	background-color: $c-white;
	border-radius: 16px;
	box-shadow: $box-shadow-soft;
	color: currentColor;

	@include mobile {
		padding: rem(20) rem(24);
		gap: rem(20);
	}

	&:hover {
		color: currentColor;
		box-shadow: $box-shadow-strong;
	}
}

.AtOneGlance--text-content {
	display: flex;
	flex-direction: column;
}

.AtOneGlance--item-title {
	font-family: $title-font-family;
	font-size: rem(32);
	line-height: 1.2;
	color: $c-gray-new-text;

	@include mobile {
		font-size: rem(24);
	}
}

.AtOneGlance--item-text {
	font-size: rem(16);
	color: $c-gray-new-text-light;

	@include mobile {
		display: none;
	}
}
