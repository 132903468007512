@use 'sass:math';

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Filter Component Styles */
.Filter {
	@include component-gap-small;
}

.Filter--title-container,
.Filter--content {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 0 rem(48);

	@include mobile {
		gap: 0 rem(12);
	}
}

.Filter--filter-button-section {
	display: flex;
	flex-direction: column;
	margin-bottom: rem(36);
}

.Filter--filter-button-container {
	position: relative;
	grid-column: 1 / 4;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	column-gap: rem(16);
	width: 100%;
}

.Filter--filter-selected-container {
	grid-column: 4 / 9;
	display: flex;
	flex-wrap: wrap;
	gap: rem(16);
	margin-bottom: rem(36);

	&:empty {
		display: none;
	}
}

.Filter--filter-tag {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	font-size: rem(18);
	line-height: inherit;
	text-align: left;
	color: $c-gray-new-text-light;
	cursor: pointer;

	&:hover {
		color: $c-gray-new-text;
	}

	&::before {
		@include bg-icon-style(remove);

		content: '';
		flex-shrink: 0;
		margin-top: rem(1);
		margin-right: rem(4);
		width: rem(16);
		height: rem(16);

		@include mobile {
			margin-top: 0;
		}
	}

	@include mobile {
		font-size: rem(14);
		line-height: 1.2;
	}
}

.Filter--title-container {
	margin: 0 0 rem(48);

	@include mobile {
		margin-bottom: rem(36);
	}
}

.Filter--title,
.Filter--lead {
	grid-column: 4 / 13;
	font-family: $title-font-family;

	@include mobile {
		grid-column: 1 / 13;
	}
}

.Filter--title {
	@include coloredText;

	margin: 0;
	font-size: rem(80);
	font-weight: 500;
	line-height: 1.2;
	color: $c-gray-new-text;

	@include mobile {
		font-size: rem(56);
	}
}

.Filter--lead {
	margin-top: rem(20);
	font-size: rem(24);
	line-height: 1.4;

	@include mobile {
		margin-top: rem(18);
		font-size: rem(22);
	}
}

.Filter--button-open-filter {
	display: none;

	@include mobile {
		display: flex;
	}
}

.Filter--filter-reset,
div.Filter--filter-reset {
	position: relative;
	display: flex;
	gap: rem(4);
	font-size: rem(18);
	line-height: inherit;
	color: $c-red-new;
	padding: 0 0 0 rem(24);
	text-align: left;

	&:hover {
		color: $c-red-new-hover-dark;
	}

	&::before {
		@include bg-icon-style(reset);

		content: '';
		position: absolute;
		top: rem(8);
		left: 0;
		width: rem(16);
		height: rem(16);
	}

	@include mobile {
		font-size: rem(14);
		padding-left: rem(20);
		padding-bottom: 0;

		&::before {
			top: rem(3);
		}
	}
}

.Filter--filter {
	grid-column: 1 / 4;

	@include mobile {
		grid-column: 1 / 13;
	}
}

.Filter--panel {
	@include mobile {
		position: fixed;
		top: 0;
		left: 0;
		display: none;
		justify-content: center;
		width: 100%;
		height: 100%;
		z-index: 100;
	}
}

.Filter--panel-open {
	.Filter--panel {
		@include mobile {
			display: flex;
		}
	}
}

.Filter--panel-background {
	@include mobile {
		position: absolute;
		inset: 0;
		background-color: rgba(34, 34, 34, 0.2);
	}
}

.Filter--panel-content {
	@include mobile {
		display: flex;
		flex-direction: column;
		position: relative;
		background-color: $c-white;
		margin: rem(72) 0;
		max-height: calc(100% - #{2 * rem(72)});
		max-width: 100%;
		width: 480px;
	}
}

.Filter--panel-content-inner {
	@include mobile {
		padding: rem(32) rvw($side-padding-mobile, $site-width-mobile);
		overflow-y: auto;
		height: 100%;
	}
}

.Filter--filter-group-title {
	font-size: rem(18);
	font-weight: 800;
	color: $c-gray-new-text;
	margin: rem(40) 0 rem(16);

	&:first-of-type {
		margin-top: 0;
	}
}

.Filter--filter-sub-title {
	font-size: rem(15);
	font-weight: 500;
	color: $c-gray-new-text;
	margin: rem(10) 0;
}

.Filter--filter-item-with-expander {
	display: flex;
	gap: rem(4);
	align-items: stretch;
}

.Filter--filter-expander-trigger {
	position: relative;
	width: rem(20);

	&::before {
		@include bg-icon-style(chevron-down);

		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: rem(14);
		height: rem(14);
		margin-top: rem(1);
	}

	&[aria-expanded='true'] {
		&::before {
			mask-image: url(#{icon-encoded(chevron-up)});
		}
	}
}

.Filter--filter-expander-panel {
	display: none;
	margin-bottom: rem(10);

	&[aria-hidden='false'] {
		display: block;
	}
}

.Filter--filter-expander-panel-content {
	display: flex;
	flex-direction: column;
	gap: rem(8);
}

.Filter--filter-label,
.Filter--filter-item-with-expander > .Filter--filter-label-text {
	position: relative;
	display: block;
	padding: rem(10) 0 rem(10) rem(32);
	font-size: rem(18);
	line-height: 1.25;
	color: $c-gray-new-text;
	cursor: pointer;

	&:has(input[disabled]) {
		cursor: not-allowed;
	}
}

.Filter--filter-item-with-expander > .Filter--filter-label-text {
	padding-left: 0;
}

.Filter--filter-label-text {
	display: block;
}

.Filter--filter-check {
	+ .Filter--filter-label-text {
		&::before {
			@include bg-icon-style(checkbox-unchecked);

			content: '';
			position: absolute;
			top: rem(14);
			left: rem(2);
			width: rem(15);
			height: rem(15);
		}
	}

	&:checked + .Filter--filter-label-text {
		&::before {
			mask-image: url(#{icon-encoded(checkbox-checked)});
		}
	}

	&:indeterminate + .Filter--filter-label-text {
		&::before {
			mask-image: url(#{icon-encoded(checkbox-indertermined)});
		}
	}

	&[disabled] {
		border-color: $c-gray-new-text-disabled;

		+ .Filter--filter-label-text {
			color: $c-gray-new-text-disabled;
		}
	}
}

.Filter--filter-item-level-1 {
	padding-left: rem(32);
}

.Filter--panel-close {
	display: none;
	position: absolute;
	top: rem(32);
	right: rvw($side-padding-mobile, $site-width-mobile);
	width: rem(36);
	height: rem(36);
	border: 1px solid $c-gray-light-darker-new;
	border-radius: 50%;

	&::before {
		@include bg-icon-style(close);

		content: '';
		position: absolute;
		inset: 0;
		mask-image: url(#{icon-encoded(close)});
		mask-size: 75%;
	}

	@include mobile {
		display: initial;
	}
}

.Filter--panel-search {
	display: none;
	margin-top: rem(36);
	width: 100%;

	@include mobile {
		display: initial;
	}
}

.Filter--results-section {
	display: flex;
	flex-direction: column;
	grid-column: 4 / 13;
	min-height: rem(240);
	scroll-margin-top: rem(180);

	@include mobile {
		grid-column: 1 / 13;
	}
}

.Filter--results-wrapper {
	position: relative;
}

.Filter--results-loading-overlay {
	position: absolute;
	inset: 0;
	background-color: $c-white;
	z-index: 2;
	display: none;

	.Filter--loading & {
		display: block;
	}
}

.Filter--results-loading-overlay-sticky {
	position: sticky;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	max-height: rem(520);
	top: 0;

	@include mobile {
		max-height: rem(320);
	}

	&::after {
		content: '';
		width: rem(120);
		height: rem(120);
		background-image: url(#{icon-encoded(spinner)});
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		animation: rotating 2s linear infinite;
	}
}

.Filter--results-heading-container {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&[hidden] {
		display: none;
	}
}

.Filter--results-title,
.Filter--results-overview-text {
	color: $c-gray-new-text-light;
	font-size: rem(18);

	&:empty {
		display: none;
	}
}

.Filter--results {
	display: flex;
	flex-direction: column;
	gap: rem(32);
	margin-top: rem(16);
}

.Filter--result-type-media {
	.Filter--results {
		flex-direction: row;
		flex-wrap: wrap;

		> .FilterCard,
		> .FilterCardMedia {
			flex: 0 0 calc(33.3% - #{rem(math.div(2 * 32, 3))});

			@include mobile {
				flex: 0 0 calc(50% - #{rem(math.div(2 * 32, 2))});
			}

			@include mobile-portrait {
				flex: 0 0 100%;
			}
		}
	}
}

.Filter--no-results-title {
	font-size: rem(28);
}
