/* Collapsible Component Styles */

.Collapsible--toggle {
	display: none;
	appearance: none;
	border: 0;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0.4;
}

.Collapsible--content {
	max-height: 0;
	transition: padding 0.1s ease-in-out, opacity 0.2s ease-in 0.05s;
	overflow: hidden;
	padding: 0;
	opacity: 0;

	.Collapsible--toggle:checked + .Collapsible--title + & {
		max-height: none;
		padding: rem(24) 0 0 0;
		opacity: 1;
	}
}

.Collapsible--title {
	@include h3;
	font-weight: bold;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: baseline;
	cursor: pointer;

	@include mobile {
		flex-flow: column nowrap;
	}
}

.Collapsible--cta {
	@include font-size(13);
	white-space: nowrap;
	margin-left: 1em;
	@include icon(angle-down, 'after');

	color: $c-red-new;

	&:hover {
		color: $c-red-new-hover-dark;
	}

	&:before {
		content: attr(data-expand);
	}

	&:after {
		margin-left: 1ex;
		transition: transform $transition-duration-fast ease-in-out;
		transform: rotate(0deg);
	}

	// Expanded state
	.Collapsible--toggle:checked + .Collapsible--title & {
		&:before {
			content: attr(data-collapse);
		}

		&:after {
			transform: rotate(180deg);
		}
	}

	@include mobile {
		margin: mobile-rem(11) 0 0 0;
	}
}

.Collapsible.footer {
	.Collapsible--title {
		@include title-font;
		@include font-size(20);
		font-weight: bold;
		margin-bottom: rem(20);
		display: block;
		cursor: auto;
		letter-spacing: rem(-0.33);
	}

	.Collapsible--cta {
		display: none;
	}

	.Collapsible--content {
		opacity: 1;
		max-height: none;
	}

	.Collapsible--toggle:checked + .Collapsible--title + .Collapsible--content {
		padding: 0;
	}

	@include mobile {
		.Collapsible--title {
			cursor: pointer;
			margin-bottom: 0;
			padding: mobile-rem(20) mobile-rem(20) mobile-rem(20) 0;
			position: relative;
		}

		.Collapsible--content {
			max-height: 0;
			opacity: 0;
		}

		.Collapsible--cta {
			display: inline-block;

			@include icon(dropdown, after);

			&:after {
				@include font-size(13, $is-mobile: true);
				color: $c-black;
				position: absolute;
				right: mobile-rem(5);
				top: mobile-rem(23);
			}

			&:before {
				display: none;
			}
		}

		.Collapsible--toggle:checked + .Collapsible--title + .Collapsible--content {
			padding: 0 0 mobile-rem(30) 0;
		}
	}
}
