/* CategoryLinkList Component Styles */
.CategoryLinkList {
	display: flex;
	flex-wrap: wrap;
	gap: rem(16);
	list-style: none;
	margin: 0;
	padding: 0;

	@include mobile {
		gap: rem(12);
	}

	.CategoryLinkList--item-hidden {
		display: none;
	}
}

.CategoryLinkList--item {
	display: inline-flex;
}
