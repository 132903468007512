/* TitleTextSection Component Styles */

.TitleTextSection {
	@include component-gap(xl);
}

.TitleTextSection--content {
	@include grid-container;
}

.TitleTextSection--inner {
	@include grid-content-standard;
}

.TitleTextSection--richtext {
	margin-top: rem(20);
}
