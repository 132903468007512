/* GroupedCategoryList Component Styles */
.GroupedCategoryList {
	@include component-gap-small;
}

.GroupedCategoryList--content {
	@include grid-container;
}

.GroupedCategoryList--inner {
	@include grid-content-standard;
}

.GroupedCategoryList--list {
	display: flex;
	flex-direction: column;
	gap: rem(24);
	margin-top: rem(24);
}

.GroupedCategoryList--list-item-title {
	@include coloredText;

	margin-bottom: rem(16);
	font-size: rem(20);
	font-weight: bold;
	line-height: 1.2;
}

.GroupedCategoryList--cta {
	margin-top: rem(36);
}
