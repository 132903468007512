/* SearchQuerySlider Component Styles */

.SearchQuerySlider {
	@include component-gap(xl);

	@include mobile {
		.wrapper {
			padding: 0;
		}
	}
}

.SearchQuerySlider--content {
	@include grid-container;

	@include mobile {
		display: flex;
		flex-direction: column;
	}
}

.SearchQuerySlider--cardslider {
	flex: 0 0 auto;

	grid-column: 2 / span 4;

	.Slider--pagination {
		margin-left: 0;
	}

	@include mobile {
		width: 100%;
		max-width: none;
		min-width: 100%;

		.Slider--gap {
			width: 20%;
		}

		.swiper-slide {
			max-width: 50%;
		}
	}

	@include mobile-xs {
		.Slider--gap {
			width: 5%;
		}

		.swiper-slide {
			max-width: 80%;
		}
	}
}

.SearchQuerySlider--textslider {
	grid-column: 7 / span 5;

	@include mobile {
		max-width: none;
		width: 100%;
		margin: mobile-rem(40) 0 0 0;
		padding: 0 rem($side-padding-mobile);
	}
}

.SearchQuerySlider--button {
	display: flex;
	align-items: center;

	&:first-child {
		grid-column: 1 / span 1;
	}

	&:last-child {
		grid-column: 12 / span 1;
		margin-left: auto;
	}

	@include mobile {
		display: none;
	}
}

.SearchQuerySlider--text-content {
	@include font-size-3;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: stretch;
	transition-duration: inherit;
	transition-delay: inherit;

	.Button {
		margin-top: rem(45 - 27);
	}
}

.SearchQuerySlider--text {
	margin-bottom: rem(27);
	width: 100%;

	@include mobile {
		display: none;
	}
}

.SearchQuerySlider--header {
	@include Card--header;
	@include font-size(15);
	margin-bottom: rem(13);
	width: 100%;

	@include mobile {
		margin-bottom: mobile-rem(16);
	}
}

.SearchQuerySlider--type {
	@include Card--type;

	@include mobile {
		@include font-size(11, 12, true);
	}
}

.SearchQuerySlider--byline {
	@include Card--subtype;

	@include mobile {
		display: inline;
		@include font-size(13, 16, true);
	}
}

.SearchQuerySlider--title {
	@include title-font;
	@include font-size-1;
	font-weight: bold;
	margin-bottom: rem(27);
	width: 100%;

	@include mobile {
		@include font-size(34, 40, true);
	}
}

// Text transition anim
.SearchQuerySlider--textslider .swiper-slide {
	transition-property: opacity;
	transition-duration: inherit;
	transition-timing-function: inherit;
	transition-delay: inherit;
	opacity: 0;
	pointer-events: none;
	z-index: 0;
	position: relative;

	.SearchQuerySlider--title,
	.SearchQuerySlider--text {
		transition-property: transform;
		transition-duration: inherit;
		transition-timing-function: inherit;
		transition-delay: inherit;
		transform: translateY(0);
	}

	&.swiper-slide-next {
		.SearchQuerySlider--title,
		.SearchQuerySlider--text {
			transform: translateY(rem(10));
		}
	}

	&.swiper-slide-active {
		z-index: 2;
		opacity: 1;
		pointer-events: auto;
	}
}
