//TODO: Update to client tracking
@import url('//fast.fonts.net/t/1.css?apiType=css&projectid=8d270a1c-f8cb-4dc5-bddd-f7d1d49e4ca5');

@font-face {
	font-family: 'Frutiger Serif';
	font-weight: normal;
	src: url('../fonts/79a8cb0a-0750-49c2-99fa-9833804f2036.woff2')
			format('woff2'),
		url('../fonts/41b594af-29e4-4dde-9d19-3e77dab584cf.woff') format('woff');
	font-display: fallback;
}

@font-face {
	font-family: 'Frutiger Serif';
	// Font is technically "Medium", but we map it to Bold
	font-weight: bold;
	src: url('../fonts/3522c3c2-097e-458d-86a2-0060865cef44.woff2')
			format('woff2'),
		url('../fonts/a28baa95-21a7-4784-ad1b-2a0934cb39d8.woff') format('woff');
	font-display: fallback;
}

@font-face {
	font-family: 'Frutiger Neue';
	font-weight: normal;
	src: url('../fonts/35dc82b2-4426-4cec-b0b4-1ef59fe47181.woff2')
			format('woff2'),
		url('../fonts/3095517e-3b86-4904-8dab-4574d4389c72.woff') format('woff');
	font-display: fallback;
}

@font-face {
	font-family: 'Frutiger Neue';
	font-weight: normal;
	font-style: italic;
	src: url('../fonts/e9262d23-811c-499b-851e-5dbf23a02e17.woff2')
			format('woff2'),
		url('../fonts/cbcca728-abf0-44d7-aff4-0f3e55ace03b.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Frutiger Neue';
	// Font is technically "Medium", but we map it to Bold
	font-weight: bold;
	src: url('../fonts/dd45322b-b291-4a3d-a95e-9ec655edd9b9.woff2')
			format('woff2'),
		url('../fonts/bae5f667-d1b4-4311-a48b-f9f97c9eaf04.woff') format('woff');
	font-display: fallback;
}

@font-face {
	font-family: 'Frutiger Neue';
	font-weight: bold;
	font-style: italic;
	src: url('../fonts/05b3aaab-8230-4e1c-8f03-b30bd4f9220b.woff2')
			format('woff2'),
		url('../fonts/11091b8c-2e5f-4e8f-9b49-eabd1a0b5ef9.woff') format('woff');
	font-display: swap;
}
