@mixin SmallCard--title {
	@include title-font;
	@include font-size(34, 45);
	font-weight: bold;
	margin: 0 0 rem(6) 0;

	@include mobile {
		@include font-size(20, 26, true);
		@include line-clamp(4);
		margin: 0 0 mobile-rem(4) 0;
	}
}

@mixin SmallCard--subtitle {
	@include font-size-4;
	font-weight: normal;

	@include mobile {
		@include font-size(11, 18, true);
	}
}
