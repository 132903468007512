@use 'sass:color';

/* CategoryLink Component Styles */
.CategoryLink {
	@include transitionEffect('background-color');

	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	overflow: hidden;
	height: 100%;
	font-size: rem(14);
	line-height: 1.2;
	border-radius: rem(18);
	min-height: rem(36);
	padding: rem(2) rem(16) rem(6);
	background-color: $c-blue-gray-light;

	&:hover {
		background-color: color.adjust($c-blue-gray-light, $lightness: -5%);
	}
}
