/* ScrollTopAction Component Styles */
.ScrollTopAction {
	position: fixed;
	right: rem($side-padding);
	z-index: 99;
	bottom: rem(44);
	visibility: hidden;
	transition: visibility 200ms ease-in-out;

	&.is-visible {
		visibility: visible;
	}

	@include mobile {
		display: none;
	}

	.Button--bg {
		background-color: $c-white;
	}
}
