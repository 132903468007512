$mq-mobile-xs: 360px !default;
$mq-mobile-portrait: 400px !default;
$mq-mobile-narrow: 580px !default;
$mq-mobile: 750px !default;
$mq-tablet-portrait: 1000px !default;
$mq-tablet: 1200px !default;
$mq-desktop: ($site-width - 1px) !default;
$mq-desktop-l: 1600px;
$mq-desktop-xl: 1919px;
$mq-desktop-xxl: 2200px;

@mixin mobile-xs {
	@media (max-width: $mq-mobile-xs) {
		@content;
	}
}

@mixin above-mobile-xs {
	@media (min-width: ($mq-mobile-xs + 1px)) {
		@content;
	}
}

@mixin mobile-portrait {
	@media (max-width: $mq-mobile-portrait) {
		@content;
	}
}

@mixin above-mobile-portrait {
	@media (min-width: ($mq-mobile-portrait + 1px)) {
		@content;
	}
}

@mixin mobile-narrow {
	@media (max-width: $mq-mobile-narrow) {
		@content;
	}
}

@mixin above-mobile-narrow {
	@media (min-width: ($mq-mobile-narrow + 1px)) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: $mq-mobile) {
		@content;
	}
}

@mixin above-mobile {
	@media (min-width: ($mq-mobile + 1px)) {
		@content;
	}
}

@mixin tablet-portrait {
	@media (max-width: $mq-tablet-portrait) {
		@content;
	}
}

@mixin above-tablet-portrait {
	@media (min-width: ($mq-tablet-portrait + 1px)) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: $mq-tablet) {
		@content;
	}
}

@mixin above-tablet {
	@media (min-width: ($mq-tablet + 1px)) {
		@content;
	}
}

@mixin desktop {
	@media (max-width: $mq-desktop) {
		@content;
	}
}

@mixin above-desktop {
	@media (min-width: ($mq-desktop + 1px)) {
		@content;
	}
}

@mixin desktop-l {
	@media (max-width: $mq-desktop-l) {
		@content;
	}
}

@mixin above-desktop-l {
	@media (min-width: ($mq-desktop-l + 1px)) {
		@content;
	}
}

@mixin desktop-xl {
	@media (max-width: $mq-desktop-xl) {
		@content;
	}
}

@mixin above-desktop-xl {
	@media (min-width: ($mq-desktop-xl + 1px)) {
		@content;
	}
}

@mixin desktop-xxl {
	@media (max-width: $mq-desktop-xxl) {
		@content;
	}
}

@mixin above-desktop-xxl {
	@media (min-width: ($mq-desktop-xxl + 1px)) {
		@content;
	}
}

// Retina screens have a 1.5 pixel ratio, not 2
@mixin retina {
	@media only screen and (min-resolution: 144dpi) {
		@content;
	}
}

// printer
@mixin print {
	@media print {
		@content;
	}
}
// printer
@mixin not-print {
	@media only screen {
		@content;
	}
}
