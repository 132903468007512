/* ImageSlider Component Styles */
.ImageSlider {
	@include component-gap-smaller;
}

.ImageSlider--content {
	@include grid-container;
}

.ImageSlider--inner {
	grid-column: 2 / span 10;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

.ImageSlider--caption {
	@include font-size(14, 20);
	color: rgba($c-black, 0.5);
	margin-top: rem(14);
}
