/* LargeTeaser Component Styles */
.DoubleTeaser {
	@include component-gap;
}

.DoubleTeaser--wrapper {
	.BigCard {
		flex: 0 0 auto;
		$width: calc(50% - #{rem(24)});
		width: $width;
		max-width: $width;
		min-width: $width;

		@include mobile {
			width: 100%;
			max-width: none;
			min-width: 0;
		}
	}
}

.DoubleTeaser--card-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: rem(18) rem(48);
	margin-top: rem(24);

	@include mobile {
		margin-top: rem(18);
	}
}
