.SvgIcon {
	display: inline-block;

	> svg {
		display: block;
		width: 100%;
		height: 100%;
		fill: currentColor;
	}
}
