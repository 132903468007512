/* Table Component Styles */
.Table {
	@include component-gap-smaller;
}

.Table--content {
	@include grid-container;
}

.Table--inner {
	grid-column: 2 / span 10;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

.Table--collapsible-table {
	padding: rem(50);
	background: $c-gray-lightest;

	@include mobile {
		grid-column: 1 / span 12;

		.Collapsible--content {
			overflow-x: auto;
		}
	}
}
