/* CardTeasers Component Styles */
.CardTeasers {
	@include component-gap-small;
}

.CardTeasers--content {
	@include grid-container;
}

.CardTeasers--inner-wrapper {
	grid-column: 2 / span 10;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

.CardTeasers--list {
	list-style: none;
}

.CardTeasers--list-item {
	@include component-gap-small;

	display: grid;
	grid-template-columns: repeat(10, 1fr);
	gap: rem($grid-gap);

	@include mobile {
		display: flex;
		flex-direction: column;
		gap: 0;
	}

	&:nth-child(even) {
		.CardTeasers--list--content {
			grid-column: 1 / span 4;
		}

		.CardTeasers--list--card {
			grid-column: 6 / span 4;
		}

		@include tablet {
			.CardTeasers--list--content {
				grid-column: 1 / span 5;
			}

			.CardTeasers--list--card {
				grid-column: 6 / span 5;
			}
		}
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include mobile {
		display: block;
	}
}

.CardTeasers--list--content {
	padding-top: rem(45);
	grid-column: 6 / span 4;
	grid-row: 1;

	@include tablet {
		grid-column: 6 / span 5;
	}

	@include mobile {
		width: 100%;
		margin-left: 0;
		padding: 0 0 mobile-rem(40) 0;
	}
}

.CardTeasers--list--card {
	grid-column: 1 / span 4;
	grid-row: 1;

	@include tablet {
		grid-column: 1 / span 5;
	}

	@include mobile {
		width: 50%;
		margin: 0 auto;
	}
}

.CardTeasers--list--title {
	@include heading-xs;
	margin-bottom: rem(22);
	line-height: inherit;
}

.CardTeasers--list--lead {
	margin-bottom: rem(30);
}
