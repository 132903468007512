/* CollectionCard Component Styles */
/* CollectionCard Component Styles */

.CollectionCard {
	position: relative;
	height: rem(560);
	color: $c-white;

	@include mobile {
		height: mobile-rem(227);
	}
}

.CollectionCard--main {
	@include Card--main();
	overflow: hidden;
	background: transparent;
	align-items: flex-start;
}

.CollectionCard--title {
	@include title-font;
	@include font-size(48, 62);
	@include hyphenate;
	font-weight: bold;
	width: 100%;

	@include mobile {
		flex: 0 0 auto;
		@include font-size(26, 32, true);
		@include line-clamp(3);
	}
}

.CollectionCard--header {
	@include Card--header;
	margin-bottom: rem(72);

	@include mobile {
		margin-bottom: mobile-rem(20);
	}
}

.CollectionCard--type {
	@include Card--type;
}

.CollectionCard--subtype {
	@include Card--subtype;
}

.CollectionCard--cta {
	margin-top: rem(60);

	@include mobile {
		margin-top: mobile-rem(20);
	}
}

.CollectionCard--bg {
	z-index: -1;
}
