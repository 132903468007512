/* CollectionHeader Component Styles */
.CollectionHeader {
	margin-top: rem(80);
	margin-bottom: rem(80);

	@include mobile {
		margin-top: rem(56);
		margin-bottom: rem(56);
	}
}

.CollectionHeader--content {
	@include grid-container;
}

.CollectionHeader--inner {
	grid-column: 2 / span 10;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	box-sizing: content-box;

	@include mobile {
		grid-column: 1 / span 12;
	}
}

.CollectionHeader--title {
	@include coloredText;
	@include title-font;
	@include font-size(92, 106);
	@include hyphenate;
	font-weight: bold;
	margin: 0 0 rem(30) 0;
	text-align: center;
	width: 100%;

	@include mobile {
		@include font-size(50, 60, true);
		margin-bottom: mobile-rem(35);
	}
}

.CollectionHeader--prefix {
	display: flex;
	flex-direction: column;
	order: -1;
	margin: 0 0 rem(25) 0;
	text-align: center;
	gap: rem(7);

	@include mobile {
		@include font-size(13, 16, true);
	}
}

.CollectionHeader--prefix-type {
	display: block;
	@include heading-xs;
	line-height: 1.1;
}

.CollectionHeader--prefix-label {
	@include title-font;
	display: block;
	line-height: 1.1;
}

.CollectionHeader--prefix-sub-label {
	@include title-font;
	font-size: rem(12);
	color: $c-gray-new-text-light;
	line-height: 1.1;
}

.CollectionHeader--actions {
	position: absolute;
	top: 0;
	left: 0;

	@include mobile {
		position: static;
		order: -1;
		margin-bottom: mobile-rem(15);
	}
}
