/* CardExpander Component Styles */
.CardExpander {
	@include component-gap-standard;
}

.CardExpander--content {
	@include grid-container;
}

.CardExpander--inner-wrapper {
	grid-column: 1 / span 12;
}

.CardExpander--list-outer-wrapper {
	margin-top: rem(40);

	@include mobile {
		margin-top: rem(36);
	}
}

.CardExpander--list-wrapper {
	margin: -#{rem(12)};
	padding: #{rem(12)};
	overflow: hidden;
	transition: height $transition-duration-default ease-in-out;
	box-sizing: content-box; // needed for JavaScript height restriction
}

.CardExpander--list {
	position: relative; // needed for JavaScript offset calculations
	display: grid;
	gap: rem(48) rem(40);
	list-style: none;
	box-sizing: border-box; // resets parent box-sizing

	@include mobile {
		grid-template-columns: repeat(2, 1fr);
		gap: rem(12);
	}
}

.CardExpander--list-item {
	height: 100%;
	min-height: rem(320);

	@include mobile {
		min-height: rem(180);
	}
}

.CardExpander--expander-wrapper {
	display: flex;
	justify-content: center;
	margin-top: rem(40);

	@include mobile {
		margin-top: rem(36);
	}
}

.CardExpander--card {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: rem(32);
	font-size: rem(16);
	border-radius: rem(16);
	color: $c-gray-new-text;
	background-color: $c-blue-light;

	@include mobile {
		font-size: rem(12);
		padding: rem(12) rem(8);
		border-radius: rem(6);
	}

	&:hover {
		box-shadow: $box-shadow-soft;
	}
}

.CardExpander--card-highlighted {
	background-color: $c-red-light;
}

.CardExpander--cardTitle {
	@include hyphenate;
	word-break: break-word;

	font-family: $title-font-family;
	font-size: rem(20);
	line-height: 1.2;
	margin-bottom: rem(58);

	@include mobile {
		font-size: rem(18);
		margin-bottom: rem(12);
	}
}

.CardExpander--date {
	order: 2;
	font-size: rem(14);
	line-height: 1.2;
	margin-top: rem(8);
}

.CardExpander--categories {
	order: -1;
	margin-bottom: rem(20);
	font-size: rem(16);
	line-height: 1.2;
	font-weight: bold;

	@include mobile {
		margin-bottom: rem(12);
		font-size: rem(12);
	}
}

.CardExpander--cardAuthor {
	margin-top: auto;
}

.CardExpander--cardAuthorTitle {
	font-weight: bold;
}

.CardExpander--regularWith {
	.CardExpander--list {
		grid-template-columns: repeat(4, 1fr);

		// Hide
		.CardExpander--list-item:nth-child(n + 5) {
			display: none;
		}

		@include mobile {
			grid-template-columns: repeat(2, 1fr);
			gap: rem(12);
		}
	}

	.CardExpander--list-open {
		.CardExpander--list-item:nth-child(n + 5) {
			display: block;
		}
	}
}

.CardExpander--reducedWith {
	.CardExpander--inner-wrapper {
		@include grid-content-standard;
	}

	.CardExpander--list {
		grid-template-columns: repeat(3, 1fr);

		// Hide
		.CardExpander--list-item:nth-child(n + 7) {
			display: none;
		}

		@include tablet {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	.CardExpander--list-open {
		.CardExpander--list-item:nth-child(n + 7) {
			display: block;
		}
	}
}
