/* ProfileButton Component Styles */
.ProfileButton {
	display: inline-flex;
	position: relative;

	@include mobile {
		.Button:not(.ProfileButton--avatar) {
			@include font-size(12, 15, true);
			font-weight: normal;
			@include icon(profile, after);
			margin-right: rem(5);
			font-size: inherit;

			&:after {
				display: inline;
			}

			.Button--text {
				display: none;
			}

			&.Button:not(.minimal) .Button--bg {
				display: none;
			}

			&.Button:not(.minimal) {
				min-width: 0;
				min-height: 0;
				height: auto;
				padding: 0;
			}
		}
	}

	&.Button.circle {
		width: rem(42);
		min-width: rem(42);
		height: rem(42);
		min-height: rem(42);

		@include mobile {
			width: rem(48);
			min-width: rem(48);
			height: rem(48);
			min-height: rem(48);
		}
	}
}

.ProfileButton--toggle {
	display: none;
}

.ProfileButton--trigger {
	display: flex;
	align-items: center;
	gap: rem(10);
	cursor: pointer;
}

.ProfileButton--trigger-label {
	line-height: 1;
}

.ProfileButton--avatar {
	position: relative;
	z-index: 15;

	.Button--bg {
		transform: translate3d(0, 0, 0);
	}

	@include mobile {
		z-index: auto;

		&.Button:not(.minimal) .Button--bg {
			background: none;
		}

		.AnimatedBackdrop {
			display: none;
		}
	}
}

.ProfileButton--menu {
	display: none;
	background: $c-white;
	border-radius: rem(10);
	padding: rem(40);
	position: absolute;
	top: 100%;
	left: rem(21);
	margin-top: rem(15);
	min-width: rem(300);
	box-sizing: content-box;
	z-index: 15;

	&:before {
		content: '';
		position: absolute;
		top: rem(-9);
		left: 50%;
		transform: translateX(-50%);
		border-left: rem(10) solid transparent;
		border-bottom: rem(10) solid $c-white;
		border-right: rem(10) solid transparent;
	}

	.ProfileButton--toggle:checked + .ProfileButton--trigger + & {
		display: block;
		opacity: 0;
		transform: translateY(rem(-20)) translateX(-50%);
		animation: profile-context-open 0.4s ease forwards;

		@keyframes profile-context-open {
			from {
				opacity: 0;
				transform: translateY(rem(-20)) translateX(-50%);
			}
			to {
				opacity: 1;
				transform: translateY(0) translateX(-50%);
			}
		}
	}

	@include mobile {
		position: fixed;
		border-radius: 0;
		padding: 0;
		top: 0;
		left: 50%;
		width: 100%;
		height: 100%;
		transform: translateY(100%);
		margin-top: 0;
		min-width: 0;
		box-sizing: border-box;

		&:before {
			display: none;
		}

		.ProfileButton--trigger:checked + .ProfileButton--trigger + & {
			transform: none;
			animation: profile-overlay-open 0.4s ease forwards;

			@keyframes profile-overlay-open {
				from {
					opacity: 0;
					transform: translateY(100%);
				}
				to {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}
	}
}

.ProfileButton--menu--inner {
	@include mobile {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: rvw($side-padding-mobile, $site-width-mobile);
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}
}

.ProfileButton--menu--list {
	border-top: 1px solid $c-gray-lightest;
	padding: rem(15) 0;
	list-style: none;

	&:first-of-type {
		margin-top: rem(25);
	}

	&:last-child {
		padding-bottom: 0;
	}
}

.ProfileButton--menu--list-link {
	display: block;
	padding: rem(5) 0;
}

.ProfileButton--menu--author {
	display: flex;
	align-items: center;
}

.ProfileButton--menu--author-avatar {
	flex-shrink: 0;
	margin-right: rem(12);
	width: rem(46);
}

.ProfileButton--menu--author-suffix {
	font-weight: bold;
	@include font-size(14, 18);

	span {
		display: block;
		font-weight: normal;
		color: $c-gray;
		@include font-size(11, 20);
	}
}

.ProfileButton--menu--close {
	display: none;

	@include mobile {
		display: block;
		position: absolute;
		top: rem(10);
		right: rem($side-padding-mobile);
	}
}

.ProfileButton--menu--close-link {
	position: relative;
	width: rem(36);
	height: rem(36);

	&::before {
		@include bg-icon-style(close-big);

		content: '';
		position: absolute;
		inset: 0;
	}
}
