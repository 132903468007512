/* AuthorTag Component Styles */
.AuthorTag {
	@include font-size(14);
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	@include mobile {
		@include font-size(12, $is-mobile: true);
	}
}

.AuthorTag--image {
	flex: 0 0 auto;
	width: rem(62);
	height: rem(62);
	order: -1;
	margin-right: rem(17);
}

.AuthorTag.compact {
	display: block;

	@include mobile {
		@include font-size(10, $is-mobile: true);
	}
}
