/* SocialMediaButtons Component Styles */

.SocialMediaButtons {
	@include list-reset;
	display: flex;
	flex-flow: row nowrap;

	@include mobile-portrait {
		justify-content: space-between;
	}
}

.SocialMediaButtons--item {
	flex: 0 0 auto;
	margin-right: rem(10);

	&:last-child {
		margin-right: 0;
	}

	@include mobile-portrait {
		margin-right: 0;
	}
}
