$button-new-primary-bg-start: rgba(247, 53, 53, 0.96);
$button-new-primary-bg-start-hover: #343b62;
$button-new-primary-bg-end: #335986;
$button-new-primary-bg-end-hover: #f73535f5;

.ButtonNew {
	@include transitionEffect;
	@include font-size(14);

	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: rem(8);
	min-height: rem(48);
	min-width: rem(120);
	line-height: 1.2;
	border: 1px solid $c-gray-new-text;
	border-radius: rem(24);
	padding: rem(6) rem(24);
}

.ButtonNew.primary {
	color: $c-white;
	border: none;
	background: linear-gradient(
		154deg,
		$button-new-primary-bg-start 0%,
		$button-new-primary-bg-end 100%
	);

	&:hover {
		background: linear-gradient(
			154deg,
			$button-new-primary-bg-start-hover 0%,
			$button-new-primary-bg-end-hover 100%
		);
	}
}

.ButtonNew.secondary {
	&:hover {
		border-color: $c-red-new-hover-light;
		background-color: $c-gray-light-new;
	}
}

.ButtonNew.secondary.inverted {
	color: $c-white;
	border-color: $c-white;

	&:hover {
		background-color: rgba($c-gray-new-text, 0.12);
	}
}

.ButtonNew.primary.inverted {
	color: $c-gray-new-text;
	background: $c-white;

	&:hover {
		background-color: $c-gray-light-new;
	}
}

.ButtonNew--text {
	display: inline-flex;
	text-align: center;
}

.ButtonNew--icon {
	width: rem(16);
	height: rem(16);
}

.ButtonNew--counter {
	font-weight: bold;

	&:empty {
		display: none;
	}
}

.ButtonNew--full-width {
	width: 100%;
}
