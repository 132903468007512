/* Header Component Styles */
$border-color: rgba($c-gray-light-darker-new, 0.5);

.Header {
	position: relative;
	z-index: 3;

	&:after {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $c-black;
		opacity: 0;
		z-index: 10;
		transform: translate3d(0, 0, 0);
		pointer-events: none;
		visibility: hidden;
	}
}

.Header--mobile-menu-active {
	.Header--mobile-menu-icon {
		&::before {
			@include bg-icon-style(close-big);
		}
	}

	.Header--mobile-menu {
		display: block;
	}
}

.Header--bar {
	background: $c-white;
	padding: rem(26) 0;
	border-bottom: 1px solid $c-gray-lightest;

	@include mobile {
		padding: mobile-rem(10) 0 0 0;
		border-bottom: 0;
	}
}

.Header--wrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: stretch;
	align-items: center;

	& > * {
		margin-left: rem(10);
	}

	.Logo {
		order: -2;
		margin-left: 0;
		margin-right: rem(30);
		flex: 0 0 auto;
		display: block;
	}

	.SearchBar {
		order: -1;
		flex: 1 1 auto;
	}

	@include mobile {
		flex-flow: row wrap;
		justify-content: space-between;

		.ProfileButton {
			margin-left: auto;
		}

		.SearchBar {
			margin-left: 0;
			margin-top: mobile-rem(10);
			order: 10;
		}
	}
}

.Header.has-backdrop {
	&:after {
		pointer-events: auto;
		visibility: visible;
		animation: backdrop-in 0.2s ease forwards;

		@keyframes backdrop-in {
			from {
				opacity: 0;
			}
			to {
				opacity: 0.4;
			}
		}

		@include mobile {
			display: none;
		}
	}
}

.Header--search {
	display: none;
	position: absolute;
	width: 100%;
	z-index: -1;
	margin: 0;
	left: 0;
	right: 0;
	top: 0;
	padding-top: rem(52 + 2 * 26);
	background: $c-white;
	transform: translate3d(0, 0, 0);

	@include mobile {
		padding-top: mobile-rem(86);
	}
}

.Header--signupButton {
	@include mobile {
		@include font-size(12, 15, true);
		font-weight: normal;
		border-left: 1px solid $c-gray-lightest;
		margin-left: rem(5);

		&.Button.primary {
			color: currentColor;
		}

		&.Button:not(.minimal) .Button--bg.Button--bg {
			display: none;
		}

		&.Button:not(.minimal) {
			min-width: 0;
			min-height: 0;
			height: auto;
			padding: 0;
			padding-left: rem(10);
		}
	}
}

.Header.minimal {
	.Header--bar {
		border-bottom: 0;
	}
}

/**
 * New Styles
 */
.Header--top-bar {
	background-color: $c-gray-light-new;
	padding: rem(6) 0;
	height: rem(96);

	@include mobile {
		height: rem(56);
	}
}

.Header--top-bar-wrapper {
	display: flex;
	align-items: center;
	height: 100%;
}

.Header--secondary-nav {
	display: flex;
	align-items: center;
	gap: rem(24);
	margin-left: auto;

	@include mobile {
		display: none;
	}
}

.Header--search-form {
	position: relative;
	font-size: rem(16);
}

.Header--search-input {
	height: rem(42);
	padding: rem(6) rem(68) rem(6) rem(24);
	min-width: rem(280);
	border: 1px solid $border-color;
	border-radius: rem(21);

	@include mobile {
		min-width: 100%;
	}
}

.Header--search-submit {
	position: absolute;
	top: 50%;
	right: rem(24);
	width: rem(32);
	height: rem(32);
	transform: translateY(-50%);
}

.Header--search-icon {
	width: rem(24);
	height: rem(24);
}

.Header--login-button {
	height: rem(42);
	min-height: rem(42);
	border-radius: rem(21);
	padding-top: rem(2);
}

.Header--login-button-mobile {
	height: rem(48);
	min-height: rem(48);
	border-radius: rem(24);
	padding-top: rem(2);
}

.Header--mobile-menu-wrapper {
	position: relative;
}

.Header--mobile-menu {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: calc(100dvh - #{rem(96)});
}

.Header--mobile-menu-trigger {
	display: none;
	align-items: center;
	gap: rem(8);
	padding: rem(6);
	transform: translateX(#{rem(6)});
	margin-left: auto;

	@include mobile {
		display: flex;
	}
}

.Header--mobile-menu-trigger-text {
	font-size: rem(10);
	line-height: 1;
	color: $c-gray-new-text-light;
}

.Header--mobile-menu-icon {
	position: relative;
	width: rem(36);
	height: rem(36);

	&::before {
		@include bg-icon-style(menu-big);

		content: '';
		position: absolute;
		inset: 0;
	}
}

.Header--mobile-menu-background {
	position: absolute;
	inset: 0;
	background-color: rgba(#222, 0.5);
}

.Header--mobile-menu-content {
	position: relative;
	overflow: hidden auto;
	max-height: 100%;
	background-color: $c-white;
	z-index: 1;
}

.Header--mobile-menu-inner {
	display: flex;
	flex-direction: column;
	gap: rem(32);
	padding-bottom: rem(32);
}

.Header--mobile-menu-action-line {
	display: flex;
	gap: rem(16);
}

.Header--nav-bar {
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;

	@include mobile {
		display: none;
	}
}

.Header--nav {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	list-style: none;
	margin: 0;
	padding: 0;
}

.Header--nav-item {
	border-left: 1px solid $border-color;

	&:last-child {
		border-right: 1px solid $border-color;
	}
}

.Header--nav-mobile {
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: 0;
}

.Header--nav-item-mobile {
	border-top: 1px solid $border-color;
	margin: 0 -#{$side-padding-mobile};

	&:last-child {
		border-bottom: 1px solid $border-color;
	}
}

.Header--nav-link {
	display: flex;
	width: 100%;
	padding: rem(20);
	font-size: rem(22);
	line-height: 1;
	background-color: $c-white;
	justify-content: center;
	min-height: rem(64);

	&:hover {
		background-color: rgba($c-gray-lightest, 0.5);
	}
}

.Header--hav-link-active {
	color: $c-red-new;

	&:hover {
		color: $c-red-new-hover-dark;
	}
}

.Header--lang-nav {
	display: flex;
	align-items: center;
	gap: rem(24);
	list-style: none;
	margin: 0;
	padding: 0;
}

.Header--lang-nav-link {
	font-size: rem(16);
	color: $c-gray-new-text-disabled;

	&:hover {
		color: $c-gray-new-text;
	}
}

.Header--lang-nav-link-active {
	font-weight: bold;
	color: $c-gray-new-text;
}

.Header--lang-nav-mobile {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: rem(16);
	list-style: none;
	margin: 0;
	padding: 0;
}

.Header--lang-nav-link-mobile {
	display: flex;
	align-items: center;
	justify-content: center;
	height: rem(48);
	width: rem(48);
	color: $c-gray-new-text-light;
	border: 1px solid currentColor;
	border-radius: 50%;
}

.Header--lang-nav-link-mobile-active {
	color: $c-gray-new-text;
}

.Header--profile-button-mobile {
	display: flex;
	align-items: center;
	justify-content: center;
}
