/* ProfileTable Component Styles */
.ProfileTable {
	@include component-gap-small;

	font-size: rem(18);
}

.ProfileTable--content {
	@include grid-container;
}

.ProfileTable--content-inner-wrapper {
	@include grid-content-standard;
}

.ProfileTable--list {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: rem(12) rem(18);
	margin-top: rem(20);

	@include mobile {
		display: flex;
		flex-direction: column;
		gap: 0;
	}
}

.ProfileTable--label {
	width: rem(150);
	grid-column: 1 / span 1;

	@include mobile {
		font-size: rem(15);
		margin-top: rem(15);
		width: 100%;
	}

	&:first-child {
		@include mobile {
			margin-top: rem(0);
		}
	}
}

.ProfileTable--label-lower {
	@include hyphenate;

	padding-top: rem(10);

	@include mobile {
		padding-top: rem(0);
	}
}

.ProfileTable--value {
	grid-column: 2 / span 5;

	.CategoryLinkList {
		@include mobile {
			margin-top: rem(6);
		}
	}
}

.ProfileTable--value-protected {
	@include hyphenate;

	display: flex;
	align-items: center;
	gap: rem(6);
}

.ProfileTable--icon {
	flex: 0 0 1em;
	width: 1em;
	height: 1em;

	@include mobile {
		margin-top: 0.22em;
	}
}

.ProfileTable--value-string-protected {
	color: $c-gray-new-text-disabled;
}

.ProfileTable--linkList {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ProfileTable--link,
.ProfileTable--value p > a {
	color: $c-red-new;
	text-decoration: underline;
	text-underline-offset: rem(3);

	&:hover {
		color: $c-red-new-hover-dark;
	}
}

.ProfileTable--cta {
	margin-top: rem(20);
}
