/* Form Component Styles */

.Form {
	@include space(margin-top, xl);
	@include space(margin-bottom, xxl);
}

.Form--content {
	@include grid-container;
}

.Form--inner {
	@include grid-content-standard;
}

.Form--heading {
	@include coloredText;
	@include title-font;
	@include font-size-1;
	font-weight: bold;
	margin-bottom: rem(48);

	@include mobile {
		@include font-size(44, 50, true);
		margin-bottom: mobile-rem(25);
	}
}

.Form--lead {
	@include font-size-3;

	margin-bottom: rem(100);

	@include mobile {
		width: 100%;
		margin-bottom: mobile-rem(50);
	}
}

.Form--error {
	margin-bottom: rem(100);
	@include font-size(14);

	@include mobile {
		margin-bottom: mobile-rem(50);
	}
}

.Form--error-text {
	margin-bottom: rem(20);
	position: relative;
	padding-left: rem(31);

	&:before {
		content: '!';
		position: absolute;
		top: rem(2);
		left: 0;
		border-radius: 50%;
		border: 1px solid $c-red;
		@include font-size(13);
		font-weight: bold;
		width: rem(21);
		height: rem(21);
		line-height: rem(18);
		text-align: center;
	}
}
