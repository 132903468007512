/* EventCard Component Styles */

.EventCard {
	position: relative;
	height: rem(280);
	color: $c-cold-gray;

	@include CardHoverAnim(('.EventCard--title'), 8);

	@include mobile {
		height: mobile-rem(227);
	}
}

.EventCard--main {
	@include Card--main(small);
	background: $c-red-light;

	@include mobile {
		padding-bottom: mobile-rem(18);
	}
}

.EventCard--title {
	@include SmallCard--title;
}

.EventCard--subtitle {
	@include SmallCard--subtitle;
}

.EventCard--header {
	@include Card--header;
	margin-bottom: rem(34);
}

.EventCard--type {
	@include Card--type;
}

.EventCard--subtype {
	@include Card--subtype;
}

.EventCard--badge {
	@include Card--badge;
}
