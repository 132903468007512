$c-white: rgb(255, 255, 255);
$c-black: rgb(0, 0, 0);

$c-gray: #888;
$c-gray-light: #e5e5e5;
$c-gray-lightest: #f4f6f8;

$c-red: #e84546;
$c-red-light: #fce6e6;

$c-cold-gray: #344d62;
$c-cold-gray-medium: #739cac;
$c-cold-gray-light: #e9eef2;

$c-gray-new-text: #212121;
$c-gray-new-text-light: #666666;
$c-gray-new-text-disabled: #999999;
$c-gray-light-darker-new: #cccccc;
$c-gray-light-new: #f7f7f7;

$c-blue-light: #e7f2f6;
$c-blue-gray-light: #d3e4ef;
$c-red-new: #c03;
$c-red-new-hover-light: #ff6666;
$c-red-new-soft: #f73535;
$c-red-new-hover-dark: #903;
$c-turquoise: #02c7d9;

$g-angle: 155deg;
$g-violet-turqoise: linear-gradient($g-angle, #638 0%, $c-turquoise 100%);
$g-violet-red: linear-gradient($g-angle, #638 0%, $c-red-new-soft 100%);
