/* CategoryPageHeader Component Styles */
.CategoryPageHeader {
	margin-bottom: rem(80);

	@include mobile {
		margin-bottom: rem(56);
	}
}

.CategoryPageHeader--main {
	display: flex;
	position: relative;

	@include mobile {
		flex-direction: column;
	}
}

.CategoryPageHeader--text-content {
	flex: 1 1 auto;

	@include mobile {
		width: 100%;
	}
}

.CategoryPageHeader--title {
	@include hyphenate;
	@include coloredText;

	font-family: $title-font-family;
	font-size: rem(80);
	line-height: 1.2;
	font-weight: bold;

	@include mobile {
		font-size: rem(56);
	}
}

.CategoryPageHeader--subtitle {
	display: flex;
	margin-top: 20px;
	font-size: rem(24);

	@include mobile {
		margin-top: 18px;
		font-size: rem(22);
	}
}

.CategoryPageHeader--lead,
.CategoryPageHeader--lead-extended {
	font-family: $title-font-family;
	font-size: rem(24);
	line-height: 1.4;
	margin-top: rem(30);

	@include mobile {
		font-size: rem(22);
	}
}

.CategoryPageHeader--lead-extended {
	overflow: hidden;
	transition: height $transition-duration-default ease-in-out;
	height: 0;
}

.CategoryPageHeader--lead-extended-text {
	padding-top: rem(12);
}

.CategoryPageHeader--lead-expander {
	margin-top: rem(12);
	font-size: rem(18);
	color: $c-red-new;
	text-decoration: underline;
	text-underline-offset: 0.25em;
	scroll-margin: rem(300);

	&:hover {
		color: $c-red-new-hover-dark;
	}

	@include mobile {
		scroll-margin: rem(200);
	}
}

.CategoryPageHeader--tags {
	margin-top: rem(80);

	@include mobile {
		margin-top: rem(56);
	}
}

.CategoryPageHeader--breadcrumbs {
	margin: rem(32) 0 rem(106);

	@include mobile {
		margin-bottom: rem(36);
	}
}

.CategoryPageHeader--breadcrumbs-item {
	display: flex;
	&::after {
		content: '/';
		display: inline-block;
		margin-left: rem(8);
	}

	&:last-child {
		&::after {
			content: none;
		}
	}
}

.CategoryPageHeader--sidebar {
	flex: 1 0 rem(432);
	margin: rem(164) 0 0 rem(48);
	max-width: rem(432);

	@include tablet {
		flex: 1 0 rem(300);
		max-width: rem(300);
	}

	@include mobile {
		flex: 1 0 100%;
		width: 100%;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		margin: rem(32) 0 0;
		order: -1;
	}
}

.CategoryPageHeader--sidebar-image {
	position: relative;
	padding-bottom: 100%;
	overflow: hidden;
	border-radius: rem(16);
}

.CategoryPageHeader--image {
	position: absolute;
	inset: 0;
	height: 100%;
	width: 100%;
	margin-top: 0;
}
