@mixin list-reset {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

@mixin object-fit-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;

	/* stylelint-disable */
	font-family: 'object-fit: cover'; // for polyfill
	/* stylelint-enable */
}

@mixin textantialiasing() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin hyphenate {
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
	hyphenate-limit-chars: 10 4 4;
}

@mixin text-selection {
	// Set custom background and font color for text selection.
}

@mixin full-size-absolute($use-pos: false) {
	position: absolute;
	top: 0;
	left: 0;
	@if ($use-pos) {
		right: 0;
		bottom: 0;
	} @else {
		width: 100%;
		height: 100%;
	}
}

@mixin gradient-link($endPos: 200%, $addHover: true) {
	font-weight: bold;
	color: $c-red;
	transition: color $transition-duration-slow ease-in-out;

	@if ($addHover) {
		@include no-touch {
			&:hover {
				color: $c-cold-gray;
			}
		}
	}
}

@mixin line-clamp($num-lines) {
	overflow: hidden;

	@if ($num-lines == 1) {
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;
	} @else {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: $num-lines;
	}
}

/**
 * Hide only visually, but have it available for screen readers:
 * See http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
@mixin visuallyhidden {
	position: absolute;
	border: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	width: 1px;
	white-space: nowrap; /* https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe */
}

@mixin reset-visuallyhidden {
	position: static;
	clip: auto;
	clip-path: none;
	height: auto;
	margin: 0;
	overflow: visible;
	width: auto;
	white-space: inherit;
}

// Mixin to target IE11 browser
@mixin ie11 {
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		@content;
	}
}

// Mixin to target safari browser
@mixin safari {
	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-appearance: none) {
			@content;
		}
	}
}

// Special mixin to target old IE versions (pre Chromium)
@mixin oldEdge($includeIE11: false) {
	@supports (-ms-ime-align: auto) {
		@content;
	}

	@if ($includeIE11) {
		@include ie11 {
			@content;
		}
	}
}

@mixin transitionEffect(
	$property: all,
	$duration: $transition-duration-default,
	$timing: ease-in-out,
	$delay: 0
) {
	transition: $property $duration $timing $delay;
}

@mixin coloredText($bgColor: $c-turquoise, $bgImage: $g-violet-turqoise) {
	background-color: $bgColor;
	background-image: $bgImage;
	background-clip: text;
	-webkit-background-clip: text;
	background-size: 100%;
	background-repeat: repeat;
	-webkit-text-fill-color: transparent;
}
