/* ProfileTable Component Styles */
.SectionLead {
	margin-top: rem(80);
	margin-bottom: rem(20);

	@include mobile {
		margin-top: rem(56);
	}

	& + div,
	& + section {
		margin-top: 0;
	}
}

.SectionLead--content {
	@include grid-container;
}

.SectionLead--content-inner-wrapper {
	@include grid-content-standard;
}
