/* TextContent Component Styles */

.TextContent {
	@include component-gap-smallest;
}

.TextContent--content {
	@include grid-container;
}

.TextContent--inner {
	@include grid-content-standard;
}

.TextContent.initial {
	.richtext p:first-child:first-letter {
		@include title-font;
		font-size: 366%;
		float: left;
		line-height: 0.3;
		margin: 0.36em 0.2ex 0 0;
	}
}
