/* FocusArticle Component Styles */
.FocusArticle {
	@include component-gap-standard;
}

.FocusArticle--content {
	display: flex;
	flex-wrap: wrap;
	margin-top: rem(20);

	@include mobile {
		overflow: hidden;
		box-shadow: $box-shadow-strong;
		border-radius: rem(8);
	}
}

.FocusArticle--content-image,
.FocusArticle--content-text {
	width: 50%;

	@include mobile {
		width: 100%;
	}
}

.FocusArticle--image-inner-wrapper {
	overflow: hidden;
	margin-top: rem(96);
	margin-right: -#{rem(72)};
	border-radius: rem(16);

	@include mobile {
		margin-top: 0;
		margin-right: 0;
		border-radius: 0;
		border-radius: rem(8) rem(8) rem(0) rem(0);
	}
}

.FocusArticle--content-text {
	position: relative;
	z-index: 1;
}

.FocusArticle--text-inner-wrapper {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	margin-left: rem(24);
	padding: rem(48);
	background-color: $c-white;
	border-radius: rem(16);
	box-shadow: $box-shadow-strong;
	color: $c-gray-new-text;

	@include mobile {
		margin-left: 0;
		padding: rem(24);
		box-shadow: none;
	}
}

.FocusArticle--text-title {
	@include hyphenate;

	display: flex;
	flex-direction: column;
	gap: rem(16);
	font-family: $title-font-family;
	font-size: rem(40);
	line-height: 1.2;

	@include mobile {
		font-size: rem(32);
	}
}

.FocusArticle--text-super-title {
	font-family: $base-font-family;
	font-size: rem(16);
	font-weight: bold;
}

.FocusArticle--text-description,
.FocusArticle--text-last-updated {
	font-size: rem(18);
}

.FocusArticle--text-description {
	margin-top: rem(24);
}

.FocusArticle--text-last-updated {
	color: $c-gray-new-text-light;
	margin-top: rem(12);
}

.FocusArticle--article-link {
	margin-top: rem(40);
	align-self: flex-start;

	@include mobile {
		margin-top: rem(36);
	}
}

.FocusArticle--no-image {
	.FocusArticle--text-inner-wrapper {
		margin-left: 0;
	}
}
